import { apiUrl } from "../../utils/constants";
import { GET, POST, DELETE } from "../api.service.wrapper";

export const FetchRootFolders = async (params) => {
  let response = await GET(apiUrl.root_folders, params);
  return response;
};

export const DeleteRootFolder = async (id) => {
  const response = await DELETE(apiUrl.root_folders, id);
  return response;
}

export const CreateRootFolders = async (data) => {
  let response = await POST(apiUrl.root_folders, data);
  return response;
};
export const getPreviousFolders = async (params) => {
  let response = await GET(apiUrl.previous_folder, params);
  return response;
};

export const getRecentUploadListing = async (params) => {
  let response = await GET(apiUrl.result_table, params);
  return response;
};
