import React, { useEffect, useState } from 'react'
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { BiEditAlt, BiMessageSquareDetail } from "react-icons/bi";
import { useForm } from "react-hook-form";
import ThemeModal from "../../ThemeModal";
import { DeleteRed } from "../../SvgIcons";
import swal from "sweetalert";
import { MoneyFormat } from "../../../utils/helpers";
import { constants } from "../../../utils/constants";
import {
    DeleteOrderProductVariant,
    UpdateOrderProductVariant,
} from "../../../services/product_variants.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage, GetUserRole } from "../../../services/auth/auth.service";
import { useHistory } from "react-router";

const VendorDetailsBox = ({ setVendorPop, vendor_data, setActiveVendor, setActiveStore, order_status, updateProductVariant, setUpdateProductVariant }) => {
    const history = useHistory();
    const role = GetUserRole()
    const [modalShow, setModalShow] = useState(false);
    const [modalData, setModalData] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    //Form Hook
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange",
    });

    const onSubmit = async (data) => {
        setDisableButton(true)
        if (data) {
            await data?.productQty?.forEach((item, index) => {
                let id = data?.variantId[index];
                let quantity = { quantity: item }
                UpdateOrderProductVariant(id, quantity).then((data) => {
                    if (data.success) {
                        setDisableButton(false)
                    }
                }).catch((error) => {
                    setDisableButton(false)
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            })

            await setUpdateProductVariant(!updateProductVariant);
            setModalShow(false);
        }
    };

    const EditProduct = ({ vendor_data }) => {
        return (
            <div className={"modalBodyContent"}>
                <Form className="variantForm" onSubmit={handleSubmit(onSubmit)}>
                    {
                        vendor_data && vendor_data.length > 0 ?
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ul className={"list-products list-unstyled"}>
                                        {
                                            vendor_data.map((vdata, index) => {
                                                return (
                                                    <li>
                                                        <div>
                                                            <span>Color: {vdata.color}</span>
                                                            <span>Size: {vdata.size}</span>
                                                        </div>
                                                        <div>
                                                            <span>{MoneyFormat(vdata.price)}
                                                                <b>
                                                                    <Form.Control className={"inlineControl"}
                                                                        defaultValue={vdata.id}
                                                                        style={{ display: 'none' }}
                                                                        {...register(`variantId[]${index}`)}
                                                                        type="text"
                                                                        readonly={true}
                                                                    />
                                                                    <Form.Control
                                                                        className={"inlineControl"}
                                                                        placeholder="Quantity"
                                                                        defaultValue={vdata.quantity}
                                                                        {...register(`productQty[]${index}`, {
                                                                            required: {
                                                                                value: "required",
                                                                                message: "Product Quantity is required"
                                                                            },
                                                                        })} type="number"
                                                                    />
                                                                    {errors.productQty && <Form.Text
                                                                        className="text-muted validationText hasError">{errors.productQty.message}</Form.Text>}
                                                                </b>
                                                            </span>
                                                        </div>
                                                    </li>
                                                )

                                            })
                                        }

                                    </ul>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <input type={"submit"} disabled={disableButton}
                                        className={"btn btn-green-theme py-2 px-12 mt-3"}
                                        value={"Submit"} />
                                </Col>
                            </Row>
                            : <span>No Data Found</span>
                    }
                </Form>
            </div>
        )
    }

    const onDeleteVariant = (id) => {

        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this Variant?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then(async (willShip) => {
                if (willShip) {
                    await DeleteOrderProductVariant(id).then((data) => {
                        if (data.success) {
                            setUpdateProductVariant(!updateProductVariant);
                            swal(data.message, { icon: "success", });
                        } else {
                            toast.error(data.message);
                        }
                    }).catch((error) => {
                        if (error.response.status == 401) {
                            EmptyLocalStorage()
                            history.push('/');
                        } else {
                            return toast.error(error.response.data.message);
                        }
                    })
                }
            });
    }

    const VendorAccordion = (vendors) => {
        let orderStatus = (order_status != constants.ORDERSTATUS.ORDERED) ? 'none' : ''
        if (vendors) {
            let loop = [];
            for (let count in vendors) {
                let store_name = vendors[count][0].store.name;
                let vendor_id = vendors[count][0].store.user_id;
                let myData = <Accordion className={"vendor-accordion"}>
                    <Accordion.Item eventKey={count} style={{ border: 'none' }}>
                        <Accordion.Header>{store_name}</Accordion.Header>
                        <Accordion.Body>
                            {
                                vendors[count].flatMap((data) => {
                                    return (
                                        <div className={"singleProduct"}>
                                            <div className={"productOption"}>
                                                <h5>{data?.product?.name}</h5>
                                                {
                                                    (role == constants.ROLES.ROLE_CSA) ?
                                                        <div>
                                                            <Button style={{ display: orderStatus }}
                                                                className={"link-theme"}
                                                                onClick={() => {
                                                                    reset()
                                                                    setModalData(data.variants)
                                                                    setModalShow(!modalShow)
                                                                }}>
                                                                <BiEditAlt />
                                                            </Button>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            <hr />
                                            <ul className={"list-products list-unstyled"}>

                                                {data.variants ?
                                                    data.variants.map((vdata) => {

                                                        let total_amount = vdata.price * vdata.quantity;
                                                        return (
                                                            <li>
                                                                {
                                                                    (role == constants.ROLES.ROLE_CSA) ?
                                                                        <div style={{ cursor: "pointer" }}>
                                                                            <span onClick={() => onDeleteVariant(vdata.id)} style={{
                                                                                display: orderStatus
                                                                            }}>
                                                                                <DeleteRed />
                                                                            </span>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                <div>
                                                                    <span>Color: {vdata.color}</span>
                                                                    <span>Size: {vdata.size}</span>
                                                                </div>
                                                                <div className={"ms-auto"} style={{ textAlign: 'right' }}>
                                                                    <span>Price: {vdata.price}</span>
                                                                    <span>Quantity: <b>{vdata.quantity}</b></span>
                                                                </div>
                                                            </li>
                                                        )
                                                    }) : ''}

                                                {/*<li>
                                                    <div>
                                                        <span>Product Price</span>
                                                    </div>
                                                    <div className={"ms-auto"}>
                                                        <span><b>{MoneyFormat(data.total_product_price)}</b></span>
                                                    </div>
                                                </li>*/}
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                            {
                                (role == constants.ROLES.ROLE_CSA) ?
                                    <div>
                                        <Button className={"btn btn-green-theme hasNotifications w-100"}
                                            onClick={async () => {
                                                await setActiveVendor(vendor_id)
                                                await setActiveStore(store_name.toUpperCase())
                                                setVendorPop(true)
                                            }}>
                                            <span>
                                                <BiMessageSquareDetail />
                                            </span> Chat Now
                                        </Button>
                                    </div> : ''
                            }

                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                loop.push(myData);
            }

            return loop;
        }
    }

    return (
        <div className={"PanelBox"}>
            <h3>Vendors</h3>
            <hr />

            {VendorAccordion(vendor_data)}

            {/* Modal Form to edit Product */}
            <ThemeModal title={"Edit Product"} content={<EditProduct vendor_data={modalData} />} size={"md"}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                    reset()
                }} />
        </div>
    )
}

export default VendorDetailsBox
