import { constants, apiUrl } from '../utils/constants';
import { GET, POST, PUT, DELETE, PATCH } from './api.service.wrapper';
import { GetAuthUser, GetUserRole } from "../services/auth/auth.service"
import { createUser } from "./firebase.service";
import { api, axiosService } from './api.service'

const user = GetAuthUser();
let role = GetUserRole();

export const GetProfile = async () => {
    let response = await GET(apiUrl.profile);
    return response;
}

export const UpdateProfile = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await POST(apiUrl.update_profile, data, config);
    return response;
}

export const GetUsers = async (keyword = '', role_id = '', page = '', perPage = '', agent = '', officer = '', user_type = '') => {
    let params = {};
    let endpoint = ''

    if (page) {
        params['page'] = page;
    }

    if (agent) {
        params['agent'] = agent;
    }

    if (officer) {
        params['officer'] = officer;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }
    if (user_type) {
        params['user_type'] = user_type;
    }
    if (role_id) {
        // params['role_id'] = role_id;
        if (role_id == 1) {
            endpoint = apiUrl.clients
        }
        if (role_id == 2) {
            endpoint = apiUrl.representatives
        }
        if (role_id == 3) {
            endpoint = apiUrl.officers
        }
        if (role_id == 4) {
            endpoint = apiUrl.agents
        }

    }

    if (keyword) {
        params['search'] = keyword;
    }

    const response = await GET(endpoint, params);
    return response;
}

export const GetDeletedUsers = async (keyword = '', role_id = '', page = '', perPage = '', agent = '', officer = '', user_type = '') => {
    let params = {};
    let endpoint = ''

    if (page) {
        params['page'] = page;
    }

    if (agent) {
        params['agent'] = agent;
    }

    if (officer) {
        params['officer'] = officer;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }
    if (user_type) {
        params['user_type'] = user_type;
    }
    if (role_id) {
        // params['role_id'] = role_id;
        if (role_id == 1) {
            endpoint = apiUrl.getDeletedClients
        }
        if (role_id == 2) {
            endpoint = apiUrl.representatives
        }
        if (role_id == 3) {
            endpoint = apiUrl.officers
        }
        if (role_id == 4) {
            endpoint = apiUrl.agents
        }

    }

    if (keyword) {
        params['search'] = keyword;
    }

    const response = await GET(endpoint, params);
    return response;
}

export const GetTests = async (id = '', keyword = '', role_id = '', page = '', perPage = '') => {
    let params = {};

    if (page) {
        params['page'] = page;
    }

    if (perPage) {
        params['per-page'] = perPage;
    }

    if (role_id) {
        params['role_id'] = role_id;
    }

    if (keyword) {
        params['search'] = keyword;
    }

    if (id) {
        params['user'] = id;
    }

    var res = await api.get(apiUrl.reports, {
        params
    });

    // const response = await GET('https://ams.tekstaging.com/api/v1/user', params);
    const response = res?.data
    return response;
}

export const GetTestPdf = async (id = '', startDate = '', endDate = '', status = null
    // timeZone = ''
) => {
    let params = {};

    if (startDate) {
        params['start_date'] = startDate;
    }

    if (endDate) {
        params['end_date'] = endDate;
    }

    // if (timeZone){
    //     params['time_zone'] = timeZone;
    // }

    if (id) {
        params['user'] = id;
    }

    if (status) {
        params['status_group'] = status;
    }

    var response = await api.get(apiUrl.reportsPDF, {
        params
    });
    // const response = await GET('https://ams.tekstaging.com/api/v1/user', params);
    // const response = res?.data
    return response;
}

export const UpdateTimeSlots = async (data) => {
    const response = await POST(apiUrl.slots, data);
    return response;
}

export const GetUserById = async (id) => {
    let response = '';
    // var res = await api.get(`https://ams.tekstaging.com/api/v1/user/${id}`);
    response = await GET(apiUrl.users + '/' + id);
    // response = res?.data
    return response;
}

export const GetCommentById = async (reportId) => {
    let params = {};

    if (reportId) {
        params['report'] = reportId;
    }
    let response = '';
    response = await GET(apiUrl.comments, params);
    return response;
}


export const GetReplyById = async (commentId) => {
    let params = {};

    if (commentId) {
        params['comment'] = commentId;
    }

    let response = '';
    response = await GET(apiUrl.replies, params);
    return response;
}

export const CreateUser = async (data) => {
    const response = await POST(apiUrl.register, data);
    return response;
}

export const UpdateUser = async (id, data) => {
    const response = await PATCH(apiUrl.users, id, data);
    return response;
}

export const TakeTestNow = async (data) => {
    const response = await POST(apiUrl.reports + '/take_test_now', data);
    return response;
}

export const PostComment = async (data) => {
    const response = await POST(apiUrl.comments, data);
    return response;
}

export const PostReply = async (data) => {
    const response = await POST(apiUrl.replies, data);
    return response;
}

export const UpdateComment = async (id, data) => {
    const response = await PUT(apiUrl.comments, id, data);
    return response;
}

export const UpdateReply = async (id, data) => {
    const response = await PUT(apiUrl.replies, id, data);
    return response;
}

export const DeleteUser = async (id) => {
    const response = await DELETE(apiUrl.users, id);
    return response;
}

export const DeactivateUser = async (data) => {
    const response = await POST(apiUrl.activity, data);
    return response;
}

export const CreateAgent = async (data) => {
    const response = await POST(apiUrl.create_agent, data);
    return response;
}

export const UpdateAgent = async (id, data) => {
    const response = await PUT(apiUrl.update_agent, id, data);
    return response;
}

export const CreateOfficer = async (data) => {
    const response = await POST(apiUrl.create_officer, data);
    return response;
}

export const UpdateOfficer = async (id, data) => {
    const response = await PUT(apiUrl.update_officer, id, data);
    return response;
}

export const CreateRep = async (data) => {
    const response = await POST(apiUrl.create_rep, data);
    return response;
}

export const UpdateRep = async (id, data) => {
    const response = await PUT(apiUrl.update_rep, id, data);
    return response;
}

export const ChatNotification = async (data) => {
    const response = await POST(apiUrl.chat_notification, data);
    return response;
}


// Failed users conclusive and unconclusive:
export const FailedUsers = async (data) => {
    return await POST(apiUrl.update_bac_status, data);
}


// Tempered users verified and marked pass:
export const TemperedUsers = async (data) => {
    return await POST(apiUrl.tempered_user, data);
}


// Restore Deleted users
export const RestoreDeletedUser = async (data) => {
    const response = await POST(apiUrl.restore_user, data);
    return response;
}

export const RevertUpdate = async (data) => {
    const response = await POST(apiUrl.revert_update, data);
    return response;
};
