import React, {useLayoutEffect, useRef, useState} from 'react'
import {Form, Label, Button, InputGroup, FormControl} from 'react-bootstrap'
import logoIcon from '../../assets/images/logo-full.svg'
import { useForm } from "react-hook-form"
import { useHistory } from "react-router";
import {EmptyLocalStorage, ResetPasswordApi} from "../../services/auth/auth.service"
import {toast} from "react-toastify";
import {FaEye, FaEyeSlash} from "react-icons/fa";

function ResetPassword({location}) {
    const history = useHistory();
    const [pass, TogglePass] = useState(true);
    const [confirmPass, ToggleConfirmPass] = useState(true);

    const {register, handleSubmit, watch, formState: {errors}} = useForm({
        mode: "onChange"
    });

    useLayoutEffect(() => {
        if(!location.state?.email && !location.state?.verificationCode){
            history.push('/');
        }
    }, [])

    const password = useRef({});
    password.current = watch("password", "");

    const onSubmit = async data => {
        let email = location.state.email
        let otp_code = location.state.verificationCode
        let password = data.password
        let password_confirmation = data.Cpassword

        await ResetPasswordApi(email, otp_code, password, password_confirmation).then((data) => {
            if (data.success) {
                toast.success(data.message);
                history.push('/')
            }
            else{
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    return (
        <div className={"text-center forgotPassAuthLayout"}>
            <Form className={"form-resetPassword"} onSubmit={handleSubmit(onSubmit)}>
                <a href="/">
                <img className="img-fluid mb-4" src={logoIcon} alt="Imprint Logo" title="Imprint Logo"/>
                </a>
                <h1 className="h5 mb-3 font-weight-normal">Reset Password</h1>
                <Form.Group className="mb-3">
                    <div className={"pass-field"}>
                        <InputGroup controlId="passowrd">
                            <FormControl
                                placeholder="Enter New Password"
                                autoComplete={"off"}
                                {...register('password', {
                                    minLength: {
                                        value: 8,
                                        message: "Minimum length is 8"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum length is 255"
                                    }
                                })}
                                type={pass ? "password" : "text"}/>
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => TogglePass(!pass)}>
                                {
                                    pass ? <FaEye /> :  <FaEyeSlash />
                                }
                            </Button>
                        </InputGroup>
                        {errors.password &&
                        <span className={"validation-error pr-5"} role="alert">{errors.password.message}</span>}
                    </div>
                    <div className={"pass-field"}>
                        <InputGroup controlId="Cpassowrd">
                            <FormControl
                                placeholder="Confirm Password"
                                autoComplete={"off"}
                                {...register('Cpassword', {
                                    required: {
                                        value: "required",
                                        message: "Confirm password is required"
                                    },
                                    validate: value => value === password.current || "The passwords do not match",
                                    minLength: {
                                        value: 8,
                                        message: "Minimum length is 8"
                                    },
                                    maxLength: {
                                        value: 255,
                                        message: "Maximum length is 255"
                                    },
                                })}
                                type={confirmPass ? "password" : "text"}/>
                            <Button variant="outline-secondary" id="button-addon2" onClick={() => ToggleConfirmPass(!confirmPass)}>
                                {
                                    confirmPass ? <FaEye /> :  <FaEyeSlash />
                                }
                            </Button>
                        </InputGroup>
                        {errors.Cpassword && <span className={"validation-error pr-5"} role="alert">{errors.Cpassword.message}</span>}
                    </div>
                </Form.Group>



                <div className="d-grid gap-2">
                    <Button variant="primary" size="lg" type="submit" className={"btn-green-theme"}>
                        Reset Password
                    </Button>
                </div>
                <p className="mt-5 mb-3 text-muted">&copy; All rights reserved by Agent Monitoring 2024</p>
            </Form>
        </div>
    )
}

export default ResetPassword
