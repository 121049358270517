import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({ dashboardData }) => {

    const state = {
        series: dashboardData?.clients_count,
        options: {
            chart: {
                type: 'donut',
                width: "400",


            },
            dataLabels: {
                enabled: false,
                style: {
                    color: ['#333', '#999']
                }
            },
            plotOptions: {
                pie: {
                    customScale: 0.95
                }
            },

            labels: dashboardData?.clients,
            colors: ['#7393B3', '#808080'],
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%",
                        },
                        legend: {
                            position: 'bottom',
                        },
                    },
                },
            ],
        },
    };

    return (
        <ReactApexChart options={state.options} series={state.series} type="donut" />
    );
};

export default DonutChart;
