import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { HomeIcon } from "./SvgIcons";
import brandlogo from "../assets/images/LOGOAMS.svg";
import { NavLink } from "react-router-dom";
import { GetUserRole } from "../services/auth/auth.service";
import { constants } from "../utils/constants";
import { Admin, Agent, Officer, Representative } from "./SideMenu";
import { FaRegBell, FaRegUserCircle } from "react-icons/fa";
import ReadMore from "./ReadMore";
import { useHistory } from "react-router-dom";
import { IoNotificationsOutline } from "react-icons/io5";

const MobileResponsiveNavbar = ({
  notificationCount,
  notificationStatus,
  markNotificationRead,
  data,
  user,
  handleLogout,
}) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [sideBar, setSideBar] = useState();
  const [expanded, setExpanded] = useState(true);

  let history = useHistory();

  useEffect(() => {
    let role = GetUserRole();

    if (role == constants.ROLES.ROLE_ADMIN) {
      setSideBar(<Admin setShowOffcanvas={setShowOffcanvas} />);
    } else if (role == constants.ROLES.ROLE_AGENT) {
      setSideBar(<Agent setShowOffcanvas={setShowOffcanvas} />);
    } else if (role == constants.ROLES.ROLE_OFFICER) {
      setSideBar(<Officer setShowOffcanvas={setShowOffcanvas} />);
    } else if (role == constants.ROLES.ROLE_REPRESENTATIVE) {
      setSideBar(<Representative setShowOffcanvas={setShowOffcanvas} />);
    }
  }, []);

  // Retrieve user data from local storage
  var userDataString = localStorage.getItem('USER_DATA');
  // Parse the JSON string to get the user data object
  var userData = JSON.parse(userDataString);

  const navDropdownTitle = (
    <>
      <div className="userImg d-flex align-items-center gap-1">
        <div className="userImgIcon">
          <Image src={userData?.profile_image} />
        </div>
        {userData?.full_name}
      </div>
    </>
  );


  // Retrieve user data from local storage
  var userDataString = localStorage.getItem('USER_DATA');
  // Parse the JSON string to get the user data object
  var userData = JSON.parse(userDataString);

  return (
    <div>
      <div className="responsive-navbar d-md-none">
        {[false].map((expand) => (
          <Navbar expand="false" bg="light" className="header shadow">
            <Container className="MobileResContainer">

              <Navbar.Brand href="javaScript:;" className="MobileResLogo">
                <div
                  className="logo-class"
                  onClick={() => history.push("/dashboard")}
                >
                  <img
                    className="logoimg"
                    src={brandlogo}
                    style={{ height: "auto", width: "100px" }}
                  />
                </div>
              </Navbar.Brand>

              <div className="notificationWrapper">
                <span className="notificationCount">
                  {notificationCount > 10 ? "9+" : notificationCount}
                </span>
                <NavDropdown
                  align="end"
                  title={<IoNotificationsOutline />}
                  id="basic-nav-dropdown"
                  className={`notificationsDropdown ${notificationStatus ? "hasNotifications" : ""
                    }`}
                  style={{ color: "black" }}
                  onToggle={(e) => {
                    setExpanded(!expanded);
                    if (expanded == true) {
                      markNotificationRead();
                    }
                  }}
                >
                  {data
                    ? data.map((notification, index) => {
                      let url = "";

                      if (
                        notification.type ==
                        constants.NOTIFICATION_TYPES.NEW_COMMENT
                      ) {
                        url = `/manage-user/Userdetail/${notification.extras.client_id}?report_id=${notification.extras.report_id}`;
                      } else if (
                        notification.type ==
                        constants.NOTIFICATION_TYPES.NEW_REPLY
                      ) {
                        url = `/manage-user/Userdetail/${notification.extras.client_id}?report_id=${notification.extras.report_id}`;
                      } else {
                        url = "/notifications";
                      }
                      return (
                        <NavDropdown.Item key={index}>
                          <NavLink
                            className="viewAllNotificationstab"
                            to={url}
                          >
                            <div className={"notificationTile"}>
                              {/* <img
                        src={
                          notification.image
                            ? notification.image
                            : NotificationIcon
                        }
                        //   alt={"Notification"}
                      /> */}
                              <div style={{ width: "100%" }}>
                                <ReadMore textLength={"30"}>
                                  {notification.description}
                                </ReadMore>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    textAlign: "right",
                                    opacity: "0.5",
                                    display: "block",
                                    width: "100%",
                                  }}
                                >
                                  {notification.created_at}
                                </span>
                              </div>
                            </div>
                          </NavLink>
                        </NavDropdown.Item>
                      );
                    })
                    : ""}
                  <NavDropdown.Item className={"viewAllNotifications"}>
                    <NavLink
                      className={"viewAllNotificationstab"}
                      to="/notifications"
                    >
                      Show all notifications
                    </NavLink>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              <NavDropdown
                align="end"
                title={navDropdownTitle}
                id="basic-nav-dropdown"
                className={"profileDropdown"}
              >
                <NavDropdown.Item>
                  {" "}
                  <NavLink className="navLinkStyle" to="/profile">
                    Profile
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink className="navLinkStyle" to="/change-password">
                    Change Password
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout} href="javascript:;">
                  Logout
                </NavDropdown.Item>
              </NavDropdown>

              <Navbar.Toggle
                className="toggles"
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                onClick={() => setShowOffcanvas(true)}
              />
              <Navbar.Offcanvas
                className="dashboard_layout_wrapper"
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
                show={showOffcanvas}
                onHide={() => setShowOffcanvas(false)}
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${expand}`}
                  ></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="res-head justify-content-end flex-grow-1 pe-3 sidebar-nav">
                    <div className="d-flex flex-column flex-shrink-0 mt-2 h-100 sideBar">
                      {sideBar}
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </div>
  );
};

export default MobileResponsiveNavbar;
