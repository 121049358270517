import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Image,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import CustomDropdown from "../../components/CustomDropdown";
import { useForm, Controller } from "react-hook-form";
import { CreateUser, GetUsers } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants, userTypeData } from "../../utils/constants";
import { createUser } from "../../services/firebase.service";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";
// import { thumbnailsSettings } from "lightgallery/plugins/thumbnail/lg-thumbnail-settings";
import Select from "react-select";

import { FaFilePdf } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { PuffLoader } from "react-spinners";


const onChangeUserType = (selectedOptions) => {
  // selectedOptions will be an array of selected values
  console.log(selectedOptions);
  // You can handle the multi-select data however you need
};


let TimeZoneData = [
  {
    label: "Houston",
    value: "America/Chicago",
  },
  {
    label: "El Paso",
    value: "America/Denver",
  },
];

function AddNewUser(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [file, setFileData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  // --- PDF Upload State -----
  const [selectedFileCertificate, setSelectedFileCertificate] = useState(false);
  const [fileCertificate, setFileDataCertificate] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [removePdf, setRemovePdf] = useState(false);
  const [removeUploadPdf, setRemoveUploadPdf] = useState(false);

  // --- PDF Upload State End -----
  const [rowsAgent, setRowsAgent] = useState([]);
  const [rowsProgram, setRowsProgram] = useState([
    { label: "Pre Trial", value: "10" },
    { label: "Probation", value: "20" },
    { label: "Sober Court", value: "30" },
    { label: "Verterans Court", value: "40" },
    { label: "Volunteer Sobriety", value: "50" },
    { label: "Family Law", value: "60" },
  ]);
  const [rowsRep, setRowsRep] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [agentId, setAgentId] = useState("");
  const [officerId, setOfficerId] = useState("");
  const [disableField, setDisableField] = useState(true);
  const [selectedOptionAgent, setSelectedOption] = useState("");
  const [selectedOptionOfficer, setSelectedOptionOfficer] = useState("");
  const [selectedUserType, setSelectedUserType] = useState(null);

  const [rowsOfficer, setRowsOfficer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [nextOfficerPageUrl, setNextOfficerPageUrl] = useState(null);
  const [nextAgentPageUrl, setNextAgentPageUrl] = useState(null);
  const [nextRepPageUrl, setNextRepPageUrl] = useState(null);

  const [pageOfficer, setPageOfficer] = useState(1);
  const [pageAgent, setPageAgent] = useState(1);
  const [pageRep, setPageRep] = useState(1);

  const handleSelectAgent = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleSelectOfficer = (selectedOption) => {
    setSelectedOptionOfficer(selectedOption);
  };

  const onChangeUserType = (val) => {
    setSelectedUserType(val.length > 0 && val[0].value);
  };

  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(async () => {
    await loadUsers("", constants.ROLES.ROLE_AGENT, setRowsAgent, pageAgent, setPageAgent, nextAgentPageUrl, setNextAgentPageUrl);
    await loadUsers("", constants.ROLES.ROLE_OFFICER, setRowsOfficer, pageOfficer, setPageOfficer, nextOfficerPageUrl, setNextOfficerPageUrl);
    await loadUsers("", constants.ROLES.ROLE_REPRESENTATIVE, setRowsRep, pageRep, setPageRep, nextRepPageUrl, setNextRepPageUrl);
  }, [keyword]);

  const loadUsers = async (keyword, userRole, setRowsData, page, setPage, nextPageUrl, setNextPageUrl) => {
    if (isLoading || (nextPageUrl === null && page !== 1)) return;

    setIsLoading(true);
    await GetUsers(keyword, userRole, page, constants.PERPAGE) // Assuming 10 results per page
      .then((result) => {
        if (result.success) {
          const newOfficers = result.data.map((officer) => ({
            value: officer.id,
            label: officer.full_name || "-",
          }));
          setRowsData((prev) => [...prev, ...newOfficers]);
          setPage((prevPage) => prevPage + 1);
          setNextPageUrl(result.next_page_url); // Update nextPageUrl
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          // Handle unauthorized error
        } else {
          toast.error(error.response?.data?.message || "An error occurred");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMoreOfficer = async () => {
    if (!isLoading && nextOfficerPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_OFFICER, setRowsOfficer, pageOfficer, setPageOfficer, nextOfficerPageUrl, setNextOfficerPageUrl);
    }
  };

  const loadMoreAgent = async () => {
    if (!isLoading && nextAgentPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_AGENT, setRowsAgent, pageAgent, setPageAgent, nextAgentPageUrl, setNextAgentPageUrl);
    }
  };

  const loadMoreRep = async () => {
    if (!isLoading && nextRepPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_REPRESENTATIVE, setRowsRep, pageRep, setPageRep, nextRepPageUrl, setNextRepPageUrl);
    }
  };

  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileData(URL.createObjectURL(event.target.files[0]));
  };


  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setDisableButton(true);

    let url = "";
    let urlCertificate = "";
    if (selectedFile) {
      if (
        selectedFile.type == "image/jpeg" ||
        selectedFile.type == "image/jpg" ||
        selectedFile.type == "image/png"
      ) {
        const myFile = new File([selectedFile], `${data.full_name}`, {
          type: selectedFile.type,
        });
        let uploadImage = await UploadImageToS3(myFile);
        if (uploadImage) {
          if (!uploadImage.success) {
            toast.error(uploadImage.message);
            return false;
          }
          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          // filename = file.name;
        }
        if (selectedFileCertificate.type == "application/pdf") {
          const myFile = new File(
            [selectedFileCertificate],
            `${data.full_name}`,
            {
              type: selectedFileCertificate.type,
            }
          );
          let uploadImage = await UploadImageToS3(myFile);
          if (uploadImage) {
            if (!uploadImage.success) {
              toast.error(uploadImage.message);
              return false;
            }
            // content_type = constants.CONTENTTYPE[file.type];
            urlCertificate = uploadImage?.data;
            // filename = file.name;
          }
        }
      } else {
        setDisableButton(false);
        return toast.error("Invalid file type.");
      }
    }


    console.log("data: ", data);

    const userData = {
      full_name: data.full_name,
      state: data.state,
      email: data.email,
      phone_number: data.phone_number,
      password: data.password,
      license: data.license,
      license_no: data?.DrivingLicense,
      installation_certificate: urlCertificate,
      vehicle_info: data?.VehicleInfo,
      dob: data.dob,
      agent: data.agent.value,
      officer_primary: data.officer.value,
      timezone: data?.TimeZone?.value,
      officer_1: data.officer1 == undefined ? null : data.officer1.value,
      officer_2: data.officer2 == undefined ? null : data.officer2.value,
      officer_3: data.officer3 == undefined ? null : data.officer3.value,
      payment_date: data.payment_date,
      representative: data.representative.value,
      user_type: data.userType.map(option => parseInt(option.value)).sort((a, b) => a > b ? 1 : -1),
      program: data.program.value,
      county: data.county,
      installation_date: data.installation_date,
      device_serial: data.serial,
      profile_image: url,
      device_type: "web",
      device_token: "user",
    }


    await CreateUser(userData)
      .then(async (data) => {
        if (data) {
          toast.success("User Created Successfully");
          setValue("agent", null);
          setValue("officer", null);
          history.push("/manage-user");
          // await createUser(data.user).then(() => {
          // });
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };



  const handleChangeCertificate = (e) => {
    const file = e.target.files[0];
    if (file && file?.type !== "application/pdf") {
      setFileTypeError(true);
      setSelectedFileCertificate(null);
      setFileDataCertificate(null);
    } else {
      setFileTypeError(false);
      setSelectedFileCertificate(file);
      setFileDataCertificate(URL.createObjectURL(file));
      resetField({ certificateUpload: null })
    }
  };

  const handldeRemovefunction = () => {
    setRemovePdf(true)
    setData((prev) => {
      return ({
        ...prev,
        certificateUpload: null
      }
      )
    })
  }

  const handleUploadRemoveFunc = () => {

    setSelectedFileCertificate(false)
    setData((prev) => {
      return ({
        ...prev,
        certificateUpload: null
      }
      )
    })
  }



  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Create User</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4} className={"mb-3"}>
                <div className="d-flex flex-row align-items-center flex-wrap gap-4">
                  {file && <div className="profile-container">
                    <Image
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={file ? file : defaultImage}
                      width="120px"
                      height="50%"
                    />
                  </div>}
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      {...register("media", {
                        required: {
                          value: "required",
                          message: "Profile image is required",
                        },
                      })}
                    />
                    {errors.media && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.media.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </Col>
            </Row>

            <Row>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    // onChange={e => {
                    //   const re = /^[A-Za-z]+$/;
                    //   if (e.target.value === "" || re.test(e.target.value))
                    //     setTextOnly(textOnly);
                    // }}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Invalid full name",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    autocomplete="off"
                    autofill="off"
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="user_type">
                  <Form.Label>User Type</Form.Label>
                  <Controller
                    control={control}
                    name="userType"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name={name}
                        value={
                          value && Array.isArray(value)
                            ? value.map((val) =>
                              userTypeData.find((option) => option === val)
                            )
                            : []
                        }
                        onChange={(selectedOptions) => {
                          // Extract only the value field from selected options
                          const selectedValues = selectedOptions ? selectedOptions.map(option => option) : [];
                          onChangeUserType(selectedValues.filter(option => option.value == 20))
                          onChange(selectedValues);  // Update form state
                        }}
                        options={userTypeData}
                        isSearchable={true}
                        isMulti={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                  {errors.user_type && (
                    <Form.Text className="text-muted validationText hasError">
                      User Type is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group
                  controlId="certificateUpload"
                  onChange={(e) => handleChangeCertificate(e)}
                  className="custom-file-upload mb-0 d-flex flex-column align-items-start"
                >
                  <Form.Label className="text-left">Installation Certificate</Form.Label>
                  {data.certificateUpload ?
                    <div className="getPDF">
                      <div className="cross" onClick={handldeRemovefunction}>
                        <RxCrossCircled />
                      </div>
                      <a href={data?.certificateUpload} target="_blank" download className="d-flex flex-column align-items-center pdf-holder ">

                        <FaFilePdf style={{ fontSize: "32px", color: "#ff1300" }} />
                      </a>
                    </div>
                    : data.certificateUpload != null || selectedFileCertificate ?
                      <div className="d-flex flex-column align-items-center pdf-holder">
                        <div className="cross" onClick={handleUploadRemoveFunc}>
                          <RxCrossCircled />
                        </div>
                        <FaFilePdf style={{ fontSize: "32px", color: "#ff1300" }} />
                        <p>{selectedFileCertificate?.name}</p>
                      </div>
                      : ""
                  }

                  <Form.Control
                    type="file"
                    accept=".pdf"
                    disabled={data?.certificateUpload}
                    {...register("certificateUpload", {
                      // required: {
                      //   value: "required",
                      //   message: "Installation Certificate is required",
                      // },

                    })}
                  />
                  <Form.Label className={"toggleFileUpload mb-0"}>
                    <UploadIcon />
                    Upload Installation Certificate
                  </Form.Label>
                  {fileTypeError && (
                    <Form.Text className="text-muted validationText hasError">
                      PDF file is required
                    </Form.Text>
                  )}
                  {errors.certificateUpload && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.certificateUpload.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>



              {selectedUserType == "20" ? (
                <>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="VehicleInfo">
                      <Form.Label>Vehicle Info</Form.Label>
                      <Form.Control
                        placeholder="Enter Vehicle Info"
                        // id={"formSignUpName"}
                        {...register("VehicleInfo", {
                          required: {
                            value: "required",
                            message: "Vehicle Info is required",
                          },
                          minLength: {
                            value: 3,
                            message: "Minimum length is 3",
                          },
                          maxLength: {
                            value: 30,
                            message: "Maximum length is 30",
                          },
                        })}
                        type="text"
                      />
                      {errors.VehicleInfo && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.VehicleInfo.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="DrivingLicense">
                      <Form.Label>Driving License</Form.Label>
                      <Form.Control
                        placeholder="Enter Driving License"
                        // id={"formSignUpName"}
                        {...register("DrivingLicense", {
                          required: {
                            value: "required",
                            message: "Driving License is required",
                          },
                          minLength: {
                            value: 3,
                            message: "Minimum length is 3",
                          },
                          maxLength: {
                            value: 30,
                            message: "Maximum length is 30",
                          },
                        })}
                        type="text"
                      />
                      {errors.DrivingLicense && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.DrivingLicense.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </>
              ) : (
                ""
              )}

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Enter State"
                    id={"formSignUpName"}
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>


              <CustomDropdown
                label="Officer 1"
                controlID="officer"
                control={control}
                rowsData={rowsOfficer}
                loadMore={loadMoreOfficer}
                isLoading={isLoading}
              />

              <CustomDropdown
                label="Officer 2"
                controlID="officer1"
                control={control}
                rowsData={rowsOfficer}
                loadMore={loadMoreOfficer}
                isLoading={isLoading}
              />

              <CustomDropdown
                label="Officer 3"
                controlID="officer2"
                control={control}
                rowsData={rowsOfficer}
                loadMore={loadMoreOfficer}
                isLoading={isLoading}
              />

              <CustomDropdown
                label="Officer 4"
                controlID="officer3"
                control={control}
                rowsData={rowsOfficer}
                loadMore={loadMoreOfficer}
                isLoading={isLoading}
              />

              <CustomDropdown
                label="Agent"
                controlID="agent"
                control={control}
                rowsData={rowsAgent}
                loadMore={loadMoreAgent}
                isLoading={isLoading}
              />

              <CustomDropdown
                label="Representative"
                controlID="representative"
                control={control}
                rowsData={rowsRep}
                loadMore={loadMoreRep}
                isLoading={isLoading}
              />

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="serial">
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter serial Number"
                    {...register("serial", {
                      required: {
                        value: "required",
                        message: "Serial number is required",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 255",
                      },
                    })}
                  />
                  {errors.serial && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.serial.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    placeholder="Enter Date Of Birth"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("dob", {
                      required: {
                        value: "required",
                        message: "dob is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.dob && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.dob.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="installation_date">
                  <Form.Label>Install Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Installation Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("installation_date", {
                      required: {
                        value: "required",
                        message: "installation date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.installation_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.installation_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="payment_date">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Payment Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("payment_date", {
                      required: {
                        value: "required",
                        message: "payment date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.payment_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.payment_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="county">
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter County"
                    {...register("county", {
                      required: {
                        value: "required",
                        message: "County is required",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 255",
                      },
                    })}
                  />
                  {errors.county && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.county.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="program">
                  <Form.Label>Program</Form.Label>

                  <Controller
                    control={control}
                    name="program"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="program"
                        value={value}
                        onChange={onChange}
                        options={rowsProgram}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.agent && (
                    <Form.Text className="text-muted validationText hasError">
                      Program is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      {...register("password", {
                        required: {
                          value: "required",
                          message: "Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <Form.Control
                      placeholder="Enter Password Again"
                      {...register("password_confirmation", {
                        required: {
                          value: "required",
                          message: "Confirm Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                            value === password.current ||
                            "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      // disabled={confirmPass}
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="license">
                  <Form.Label>SPN Number</Form.Label>
                  <Form.Control
                    placeholder="Enter License"
                    id={"formSignUpName"}
                    {...register("license", {
                      required: {
                        value: "required",
                        message: "License is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.license && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.license.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                        message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                      },

                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="TimeZone">
                  <Form.Label>Timezone</Form.Label>

                  <Controller
                    control={control}
                    name="TimeZone"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="TimeZone"
                        value={value}
                        onChange={onChange}
                        options={TimeZoneData}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.TimeZone && (
                    <Form.Text className="text-muted validationText hasError">
                      Time zone is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  // disabled={disableButton}
                  value={"Create User"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddNewUser;
