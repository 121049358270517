import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function DashboardStat(props) {
    const variant = props.variant;
    const [colorVariant, setColorVariant] = useState('bg-theme-green');

    useEffect(() => {
        if (variant === "theme-green") {
            setColorVariant("bg-theme-green");
        }
        if (variant === "theme-danger") {
            setColorVariant("bg-danger");
        }
        if (variant === "theme-success") {
            setColorVariant("bg-success");
        }
        if (variant === "gradient-yellow") {
            setColorVariant("bg-c-yellow");
        }
        if (variant === "gradient-pink") {
            setColorVariant("bg-c-pink");
        }
        if (variant === "gradient-green") {
            setColorVariant("bg-c-green");
        }
        if (variant === "gradient-blue") {
            setColorVariant("bg-c-blue");
        }
    }, [])

    return (
        // Note: add the below to the order-card to get individual colors
        // ${colorVariant}
        <div className={`card order-card `}>
            <div className="card-block w-100">
                <h6 className="m-b-20 title">
                    {props?.title}
                </h6>
                <h2 className="d-flex justify-content-between align-items-center">
                    <span>
                        {props?.icon}
                    </span>
                    <span className={"ms-auto count"}>
                        {props?.countMain}
                    </span>
                </h2>
                {/*<p className="mb-0 text-center">*/}
                {/*    <Link to={"/dashboard"}>*/}
                {/*        Read More*/}
                {/*    </Link>*/}
                {/*</p>*/}
            </div>
        </div>
    )

}

export default DashboardStat