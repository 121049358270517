import ImageHere from "../assets/images/placeholder.jpg";

const FAILED = 1;
const FAILED_LATE = 5;
const FAILED_TEMPERED = 6;
const FAILED_TEMPERED_LATE = 8;

// Create an array of failed statuses
const failedStatuses = [FAILED, FAILED_LATE, FAILED_TEMPERED, FAILED_TEMPERED_LATE];

const roles = {
  ROLE_REPRESENTATIVE: 2,
  ROLE_OFFICER: 3,
  ROLE_AGENT: 4,
  ROLE_ADMIN: 5,
  // ROLE_ADMIN: 10,
  ROLE_USER: 1,
  ROLE_DESIGNER: 30,
  ROLE_VENDOR: 40,
  ROLE_CSA: 50,
  ROLE_REP: 60,
};

const orderStatus = {
  ORDERED: 5,
  CONFIRMED: 10,
  CANCELLED: 15,
  PAID: 20,
  SHIPPED: 25,
  COMPLETED: 30,
  REVIEWED: 35,
  RETURNED: 40,
};

const orderStatusKey = {
  [orderStatus.ORDERED]: "ORDERED",
  [orderStatus.CONFIRMED]: "CONFIRMED",
  [orderStatus.CANCELLED]: "CANCELLED",
  [orderStatus.PAID]: "PAID",
  [orderStatus.SHIPPED]: "SHIPPED",
  [orderStatus.COMPLETED]: "COMPLETED",
  [orderStatus.REVIEWED]: "REVIEWED",
  [orderStatus.RETURNED]: "RETURNED",
};

const page = {
  PRIVACY_POLICY: "privacy-policy",
  TERMS: "terms-and-conditions",
};

const categoryStatus = {
  CUSTOMIZE: 10,
  IN_STOCK: 20,
  DESIGN: 30,
};

const categoryStatusKey = {
  [categoryStatus.CUSTOMIZE]: "CUSTOMIZE",
  [categoryStatus.IN_STOCK]: "IN_STOCK",
  [categoryStatus.DESIGN]: "DESIGN",
};

const collections = {
  CHATROOM: "chatRoom_live",
  USERS: "users_live",
  CHATLIST: "chatlist",
  GENERALCHATLIST: "general_chat_users_live",
  GENERALCHATROOM: "general_chatRoom_live",
};

const s3Credential = {
  /* Live Credentials*/
  /* Local Credentials for Bucket */
  // FIXME : Move this to .env
  bucketName: "ams-storage-app",
  dirName: "users",
  region: "us-east-1",
  accessKeyId: "AKIAYZNONWQ2CIQFXKB4",
  secretAccessKey: "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",
  s3EndPoint: "https://ams-storage-app.s3.us-east-1.amazonaws.com",
  fileSize: "100000000",
};

const errorMessages = {
  fileSize: "File size can not be more than 100MB",
  fileSuccess: "file uploaded successfully",
  fileError: "Error in uploading file",
};

const contentType = {
  "image/jpeg": "image",
  "image/jpg": "image",
  "image/png": "image",
  "image/gif": "image",
  "application/pdf": "doc",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    "doc",
  "audio/mpeg": "audio",
  "video/mp4": "video",
};

const chatMessage = {
  CONTENT: 0,
  CONTENT_SOFT_DELETE: 1,
  CONTENT_HARD_DELETE: 2,
};

const AdType = {
  IMAGE: "image",
  VIDEO: "video",
};

const notificationTypes = {
  BlOWING_REMINDER: 10,
  MISSED_TEST: 20,
  DAILY_TEST_COMPLETED: 30,
  TEST_SUCCESSFULL: 40,
  ADMIN_TEST_SUCCESSFULL: 100,
  ADMIN_DAILY_TEST_COMPLETED: 200,
  NEW_COMMENT: 300,
  NEW_REPLY: 400,
};

export const constants = {
  LOCAL_STORAGE_TOKEN: "AUTH_ACCESS_TOKEN",
  LOCAL_STORAGE_USER: "USER_DATA",
  BASE_URL: process.env.REACT_APP_BASE_URL,
  CSA_URL: process.env.REACT_APP_CSA_BASE_URL,
  ADMIN_URL: process.env.REACT_APP_ADMIN_BASE_URL,
  VENDOR_URL: process.env.REACT_APP_VENDOR_BASE_URL,
  ROLES: roles,
  REPORT_FAILED_STATUS : failedStatuses,
  ORDERSTATUS: orderStatus,
  ORDERSTATUSKEY: orderStatusKey,
  LOCALE: "en-OM",
  CURRENCY: "OMR",
  PAGES: page,
  PERPAGE: 20,
  IMAGENOTFOUND: "https://imprint.tekstaging.com/not-found.png",
  USERIMAGENOTFOUND: "https://imprint.tekstaging.com/not-found-user.png",
  ClientImage: ImageHere,
  CATEGORYSTATUS: categoryStatus,
  NOTIFICATION_TYPES: notificationTypes,
  CATEGORYSTATUSKEY: categoryStatusKey,
  COLLECTION: collections,
  S3CREDENTIAL: s3Credential,
  ERRORMESSAGES: errorMessages,
  CONTENTTYPE: contentType,
  CHATMESSAGE: chatMessage,
  ADTYPE: AdType,
};

export const apiUrl = {
  login: "signin",
  logout: "user/logout",
  forgot_password: "user/forgot_password",
  verify_otp: "verify",
  resend_otp: "verify/send_otp",
  reset_password: "reset-password",
  change_password: "change-password",
  pages: "pages",
  orders: "orders",
  updateStatus: "update-status",
  dashboard: "dashboard/home",
  products: "products",
  categories: "categories",
  sub_categories: "sub-categories",
  profile: "profile",
  update_profile: "user/update_me",
  notifications: "notification",
  notifications_count: "notification/count",
  mark_all_as_read: "notification/mark_all_as_read",
  users: "user",
  comments: "comments",
  replies: "replies",
  clients: "user/get_clients",
  getDeletedClients: "user/get_deleted_clients",
  agents: "user/get_agents",
  officers: "user/get_officers",
  representatives: "user/get_representatives",
  reports: "reports",
  reportsPDF: "reports/download_pdf",
  register: "signup",
  slots: "slots",
  create_agent: "signup/agent",
  update_agent: "user/agent",
  activity: "user/activity",
  create_officer: "signup/officer",
  update_officer: "user/officer",
  create_rep: "signup/representative",
  update_rep: "user/representative",
  product_variants: "product-variants",
  order_product_variants: "order-product-variants",
  update_order_product_variants: "update-order-product-variant",
  remove_attachment: "remove-attachment",
  product_comparisons: "product-comparisons",
  colors: "colors",
  get_colors: "get-colors",
  ads: "ads",
  create_shipping: "create-shipping",
  track_shipping: "track-shipping",
  order_shipping: "order-shippings",
  transactions: "transactions",
  update_transaction_status: "update-transaction-status",
  fetch_countries: "fetch-countries",
  root_folders: "interlock",
  previous_folder: "interlock/back_button",
  result_table: "interlock/get_recent_uploads",
  fetch_states: "fetch-states",
  fetch_cities: "fetch-cities",
  validate_address: "check-address",
  chat_notification: "chat-notification",
  reviews: "reviews",
  web_dashboard: "web-dashboard/get_graph_data",
  tempered_user: "reports/update_status",
  update_bac_status: "reports/update_bac_status",
  restore_user: "user/recover_user",
  revert_update: "reports/revert_update"
};

export const customStyles = {
  rows: {
    style: {
      minHeight: "50px",
    },
  },
  headCells: {
    style: {
      color: "#000",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "19px",
      fontFamily: "Poppins",
      padding: "5px",
    },
  },
  cells: {
    style: {
      padding: "5px",
      fontSize: "12px",
      fontWeight: "400",
      lineHeight: "19px",
      fontFamily: "Poppins",
    },
  },
};

// ==== Styles for React Select =========

export const rectSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #ae1616' : '1px solid #ced4da',
    boxShadow: state.isFocused ? '0px 0px 4px 0px #ae1616' : null,
    borderRadius: '5px !important',
    height: '40px',
    fontSize: "12px",
    ':hover': {
      borderColor: '#ae1616',
      cursor: "pointer"
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ae1616' : 'white',
    color: state.isSelected ? 'white' : 'black',
    transition: "0.2s ease-in-out",
    marginBottom: "3px",
    ':hover': {
      backgroundColor: '#241414e8',
      color: 'white',
      cursor: 'pointer'
    },
    ':last-child': {
      marginBottom: "0px"
    }
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #ced4da',
    borderRadius: '0px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',

  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '150px',
    overflowY: 'auto',
    padding: "0px"
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#ced4da',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6c757d',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: 'black',
  }),
};

export const userTypeData = [
  {
    label: "PORTABLE UNIT",
    value: "10",
  },
  {
    label: "INTERLOCK UNIT",
    value: "20"
  }
]

export const temperdStatus = {
  MARK_PASS: 10,
  MARK_TEMPERING_VERIFIED: 20
}


export const ConclusivenessStatus = {
  CTA: 10,
  N_CTA: 20
}
