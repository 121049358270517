import React from "react";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";

const InterLockTableData = ({ data }) => {
  // Function to truncate file name and add ellipsis
  const truncateFileName = (name, maxLength = 30) => {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + "...";
    }
    return name;
  };

  return (
    <div
      style={{
        maxHeight: "250px",
        overflowY: "auto",
        overflowX: "hidden"
      }}
    >
      <Table striped bordered hover className="m-0 interLockTable">
        <thead className="TableHead">
          <tr>
            <th className="TableHeading">RECENT</th>
            <th>UPLOADS</th>
          </tr>
        </thead>
        {data != null ? (
          <tbody className="TableBodyText">
            <tr>
              <th>Folder</th>
              <th>File</th>
              <th>Date</th>
            </tr>
            {data.map((folder, i) => (
              <React.Fragment key={i}>
                {/* Iterate over the files in the folder */}
                {folder.files && folder.files.length > 0 ? (
                  folder.files.map((file, j) => (
                    <tr key={`${i}-${j}`}>
                      <td>{folder?.type ? folder?.type : "-"}</td>
                      <td className="fileNameCell">
                        {/* Add tooltip and truncation */}
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id={`tooltip-${i}-${j}`}>{file.file_name}</Tooltip>}
                        >
                          <span>{truncateFileName(file.file_name)}</span>
                        </OverlayTrigger>
                      </td>
                      <td>{file?.created_at ? file?.created_at : "-"}</td>
                    </tr>
                  ))
                ) : (
                  <tr key={i}>
                    <td>{folder?.type ? folder?.type : "-"}</td>
                    <td colSpan={2}>No Files</td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        ) : (
          <tbody className="TableBodyText">
            <tr colSpan={2}>
              <th colSpan={2}>No Data</th>
            </tr>
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default InterLockTableData;
