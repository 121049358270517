import React from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Tabs, Tab, Form, Button, Alert } from 'react-bootstrap';
import { TimePicker } from 'antd';

const { RangePicker } = TimePicker;

const ModifyScheduleTabs = () => {
    const { control, handleSubmit, errors, watch } = useForm({
        defaultValues: {
            tabs: [
                { name: 'Default', fields: Array(3).fill([null, null]) },
                { name: 'Personalized', fields: Array(3).fill([null, null]) },
                { name: '4x Days', fields: Array(4).fill([null, null]) },
                { name: '6x Days', fields: Array(6).fill([null, null]) },
                { name: '8x Days', fields: Array(8).fill([null, null]) },
            ],
        },
    });

    const { fields, append } = useFieldArray({
        control,
        name: 'tabs',
    });

    const onSubmit = (data) => {
        // Your submit logic here
    };

    const renderRangePicker = (field, index) => (
        <Controller
            key={index}
            name={`tabs[${field}].fields[${index}]`}
            control={control}
            defaultValue={[null, null]}
            rules={{ required: true, message: 'Please select start and end time' }}
            render={({ field: { onChange, value } }) => (
                <RangePicker
                    value={value}
                    onChange={(val) => onChange(val)}
                    format="HH:mm a"
                    placeholder={['Start Time', 'End Time']}

                />
            )}
        />
    );

    const renderForm = (tab, tabIndex) => (
        <Tab key={tabIndex} eventKey={tabIndex} title={tab.name}>
            <Form.Group>
                {tab?.fields?.map((_, index) => renderRangePicker(tabIndex, index))}
                {errors?.tabs && errors?.tabs[tabIndex] && (
                    <Alert variant="danger">{errors.tabs[tabIndex].message}</Alert>
                )}
            </Form.Group>
        </Tab>
    );

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Tabs defaultActiveKey="0">
                {fields?.map((tab, index) => renderForm(tab, index))}
            </Tabs>
            <Button variant="primary" type="submit">
                Save
            </Button>
        </Form>
    );
};

export default ModifyScheduleTabs;
