import React from 'react';
import ReactApexChart from 'react-apexcharts';
const BarCharts = ({ dashboardData }) => {
  const state = {
    series: [
      {
        name: 'Report(s)',
        data: dashboardData?.report_count,
      },
    ],
    export: false,

    options: {
      chart: {
        height: 350,
        type: 'bar',
      },
      colors: ['#960b0c'],
      plotOptions: {
        bar: {
          borderRadius: 3,
          dataLabels: {
            position: 'top', // top, center, bottom
          },

        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return `${val} ${val > 1 ? "" : ""}`;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories: dashboardData?.months,
        position: 'top',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: 'gradient',
            gradient: {
              colorFrom: '#c4c4c4',
              colorTo: '#BED1E6',
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {

        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val;
          },
        },
      },
      title: {
        text: 'Total Reports Per Month',
        floating: true,
        offsetY: 330,
        align: 'center',
        style: {
          color: '#444',
          fontWeight: '600',
        },
      },
    },
  };

  return (
    <>
      <ReactApexChart
        options={state?.options}
        series={state?.series}
        type="bar"
        height={350}

      />
    </>
  );
};

export default BarCharts;
