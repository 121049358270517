import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { constants, customStyles, rectSelectStyles, userTypeData } from "../../utils/constants";

import moment from "moment";
import {
  CreateUser,
  DeleteUser,
  GetUsers,
  UpdateUser,
} from "../../services/users.service";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import {
  EmptyLocalStorage,
  GetAuthUser,
} from "../../services/auth/auth.service";
import ThemeModal from "../../components/ThemeModal";
import Select from "react-select";

function Users() {
  const location = useLocation();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const current_user = GetAuthUser();
  const [imageModal, setImageModal] = useState({
    url: "",
    status: false,
  });
  const [selectedUserType, setSelectedUserType] = useState(null);
  const requestIdRef = useRef(0);

  const clearInput = () => {
    setKeyword("");
  };

  // useEffect(() => {
  //   getUsers();
  // }, [keyword, page, perPage, selectedUserType]);

  useEffect(() => {
    const handler = setTimeout(() => {
      getUsers();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [keyword, page, perPage, selectedUserType]);

  const getUsers = async () => {
    const currentRequestId = ++requestIdRef.current;
    setLoading(true);
    let agent = "";
    let officer = "";
    // if (current_user.role === constants.ROLES.ROLE_AGENT) {
    //   agent = current_user.id
    // }else if(current_user.role === constants.ROLES.ROLE_OFFICER){
    //   officer = current_user.id
    // }
    try {
      const result = await GetUsers(
        keyword,
        constants.ROLES.ROLE_USER,
        page,
        perPage,
        agent,
        officer,
        selectedUserType?.value
      )

      if (requestIdRef.current !== currentRequestId) {
        // A newer request has been initiated; ignore this response
        return;
      }

      if (result.success) {
        if (result.data) {
          const rowData = [];
          setPage(result.current_page);
          setTotalRows(result.total);
          result.data.map((dt, index) => {
            rowData.push({
              id: dt.id,
              user_short_id: dt.user_short_id,
              index: ++index,
              CategoryImg: dt.profile_image || constants.ClientImage,
              full_name: dt.full_name || "-",
              state: dt.state || "-",
              email: dt.email || "-",
              phone: dt.phone_number || "-",
              dob: moment(dt.dob).format('MM/DD/YYYY') || "-",
              spnNo: dt.license || "-",
              program: dt.program || "-",
              county: dt.county || "-",
              state: dt.state || "-",
              officer: dt.officer_name || "-",
              representative: dt.representative || "-",
              created_at: moment(dt.created_at).fromNow(),
              user_type: dt?.user_type,
              installation_certificate: dt?.installation_certificate,
              has_possible_temperred_report: dt?.has_possible_temperred_report

            });
          });
          setRows(rowData);
          setLoading(false);
        }
      } else {
        setLoading(false);
        return toast.error(result.message);
      }
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        return toast.error(error.response.data.message);
      }
    };
  };

  const onUserDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        await DeleteUser(id)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getUsers();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const ModalForImage = () => {
    return (
      <>
        <div className={"modalBodyContent"} style={{ textAlign: "center" }}>
          <img
            style={{
              objectFit: "cover",
              objectPosition: "center",
              textAlign: "center",
            }}
            src={imageModal.url}
            className="img-fluid"
          />
        </div>
      </>
    );
  };

  const onEdit = (data) => {
    history.push("/manage-user/edit-user/" + data.id, {
      data: data,
    });
  };
  const onViewPage = (data) => {
    console.log("user_type: ", data?.user_type)
    // if (data?.user_type == 20) {
    //   history.push("/manage-user/Userdetail-Interlock/" + data.id);
    // } else {
    //   history.push("/manage-user/Userdetail/" + data.id);
    // }
    history.push("/manage-user/Userdetail/" + data.id);
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={() => onViewPage(data)}>
          View
        </Dropdown.Item>

        {current_user && (current_user.role === constants.ROLES.ROLE_ADMIN || current_user.role === constants.ROLES.ROLE_AGENT) && (
          <React.Fragment>
            <Dropdown.Item as="button" onClick={() => onEdit(data)}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>
              Delete
            </Dropdown.Item>
          </React.Fragment>
        )}
      </DropdownButton>
    );
  };
  // const MyState = () => {
  //   const data = [{ id: 1, State: "Paris" }];
  //   return (
  //     <div className="users">
  //       {data.map((user) => (
  //         <div className="user">{user.State}</div>
  //       ))}
  //     </div>
  //   );
  // };

  const columns = [
    {
      id: "clientId",
      name: "S No.",
      width: "50px",
      selector: (rowData) => rowData.user_short_id,
    },
    {
      id: "CategoryImg",
      name: "Picture",
      width: "100px",
      selector: (rowData) => rowData.oid,
      format: (rowData) => (
        <div className="custom-img">
          <img
            onClick={() =>
              setImageModal({
                url: rowData.CategoryImg,
                status: true,
              })
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = constants.ClientImage;
            }}
            src={rowData.CategoryImg}
            className={"img-table img-thumbnail w-100 h-100"}
            alt={"Image"}
          />
        </div>
      ),
    },
    {
      id: "full_name",
      name: "Full Name",
      width: "200px",
      selector: (rowData) => rowData.full_name,
      format: (rowData) => (
        <NavLink
          className="text-dark text-decoration-none d-flex"
          to={{
            pathname: `/manage-user/Userdetail/${rowData.id}`,
            //   rowData?.user_type == 10
            //     ? `/manage-user/Userdetail/${rowData.id}`
            //     : `/manage-user/Userdetail-Interlock/${rowData.id}`,
            state: {
              page: page,
            },
          }}
        >
          {rowData.full_name}
        </NavLink>
      ),
    },
    {
      id: "dob",
      name: "Date of Birth",
      // maxWidth: "80px",
      selector: (rowData) => rowData.dob,
    },
    {
      id: "dob",
      name: "CASE# OR SPN",
      // maxWidth: "80px",
      selector: (rowData) => rowData.spnNo,
    },
    {
      id: "dob",
      name: "Program",
      // maxWidth: "80px",
      selector: (rowData) => (
        <div className="text-uppercase">{rowData.program}</div>
      ),
    },
    {
      id: "dob",
      name: "County",
      // maxWidth: "80px",
      selector: (rowData) => rowData.county,
    },
    // {
    //   id: "dob",
    //   name: "State",
    //   maxWidth: "50px",
    //   selector: (rowData) => rowData.state,
    // },
    {
      id: "email",
      name: "Officer",
      selector: (rowData) => rowData.officer,
    },
    {
      id: "user_type",
      name: "Device",
      selector: (rowData) => (
        Array.isArray(rowData.user_type) && rowData.user_type.length > 0
          ? rowData.user_type.map((u_type) => (
            u_type == 10 ? 'Portable' : 'Interlock'
          )).join(', ') // Join the array into a string if needed
          : ''
      )
    },
    // {
    //   id: "dob",
    //   name: "AMS Rep.",
    //   maxWidth: "50px",
    //   // selector: (rowData) => rowData.representative,
    //   cell: (rowData) => (
    //     <OverlayTrigger overlay={<Tooltip>{rowData.representative}</Tooltip>}>
    //       {
    //         <Button variant="secondary" className="custom-truncate-btn">
    //           {rowData.representative}
    //         </Button>
    //       }
    //     </OverlayTrigger>
    //   ),
    // },

    {
      id: "action",
      name: "Action",
      cell: (rowData) => userActions(rowData),
    },
  ];



  const onChangeUserType = (selectedOption) => {
    setSelectedUserType(selectedOption);
  };

  // Define the conditional row styles
  const conditionalRowStyles = [
    {
      when: row => row.has_possible_temperred_report,
      style: row => ({
        backgroundColor: 'lightyellow',
        '&:hover': {
          backgroundColor: 'white',
        },
      }),
    },
  ];


  return (
    <div className={"usersPage"}>
      <h3 className="page-heading">Manage Users</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={4} lg={5} xl={5}>

            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                className="custom-search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                style={{
                  borderRight: keyword ? "none" : "1px solid #ced4da",
                  borderTopRightRadius: keyword ? "0" : "5px",
                  borderBottomRightRadius: keyword ? "0" : "5px",
                }}
              />
              {keyword && (
                <InputGroup.Text
                  className="cross-icon"
                  id="basic-addon2"
                  onClick={clearInput}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 6.48828L18 18.4883"
                      stroke="#817A8E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 6.48828L6 18.4883"
                      stroke="#817A8E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={8} lg={7} xl={7}>
            <Row className="justify-content-end">
              <Col xs={12} sm={12} md={5} lg={5} xl={4}>
                <Select
                  name="userTypeSelect"
                  styles={rectSelectStyles}
                  onChange={onChangeUserType}
                  options={userTypeData}
                  isSearchable={false}
                  placeholder={"Filter Users"}
                  className="react-select-container mb-3"
                  classNamePrefix="react-select"
                  isClearable
                />
              </Col>
              {(current_user.role == constants.ROLES.ROLE_ADMIN || current_user.role == constants.ROLES.ROLE_AGENT) && (
                <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                  <Link to={"/manage-user/add-user"}>
                    <Button className={"btn btn-green-theme h40 w-100"}>
                      <AddIconOutline /> Add New
                    </Button>
                  </Link>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid custom-table-border user-table mt-4">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          paginationRowsPerPageOptions={[30, 60, 90, 180, 270, 360]}
          progressComponent={
            <BarLoader
              color={"#961b1a"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
          conditionalRowStyles={conditionalRowStyles} 
        />
      </div>
      <ThemeModal
        title={""}
        size={"md"}
        content={<ModalForImage />}
        show={imageModal.status}
        onHide={() =>
          setImageModal({
            url: "",
            status: false,
          })
        }
      />
    </div>
  );
}

export default Users;
