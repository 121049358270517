import React, { useContext, useEffect, useState } from 'react'
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { GetProfile, UpdateProfile } from "../../services/users.service";
import { UploadIcon } from "../../components/SvgIcons";
import { toast } from "react-toastify";
import { EmptyLocalStorage, GetAccessToken, GetUserRole, SetAuthUser } from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { updateUser } from "../../services/firebase.service";
import UserContext from "../../hooks/UserContext";
import { UploadImageToS3 } from "../../utils/helpers";

function EditProfile(props) {
    let history = useHistory();
    const [file, setFileData] = useState(false);
    const [defaultImage, setDefaultImage] = useState(false);
    const [disableButton, setDisableButton] = useState(false);


    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        mode: "onChange"
    });

    useEffect(async () => {
        getProfile();
    }, []);

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.success) {
                if (result.data) {
                    let dt = result.data;
                    setValue('id', dt.id);
                    setValue('full_name', dt.full_name);
                    setValue('email', dt.email);
                    setValue('phone_number', dt.phone_number);
                    await setDefaultImage(dt.profile_image || constants.USERIMAGENOTFOUND)
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
            toast.error(error.response.data.message);
        })
    }

    const onSubmit = async (data) => {
        let formData = new FormData();
        formData.append('full_name', data.full_name.trim());
        formData.append('email', data.email);
        formData.append('phone_number', data.phone_number);
        // if (data.media[0]) {
        //     formData.append('profile_image', data.media[0]);
        // }
        setDisableButton(true)
        let url = '';
        if (data.media.length > 0) {
            let uploadImage = await UploadImageToS3(data.media[0]);
            if (uploadImage) {
                if (!uploadImage.success) {
                    toast.error(uploadImage.message);
                    return false;
                }

                // content_type = constants.CONTENTTYPE[file.type];
                url = uploadImage.data;
                formData.append('profile_image', url);
                // filename = file.name;
            }
        }
        await UpdateProfile(formData).then(async (data) => {
            if (data?.success) {

                if (GetUserRole() == constants.ROLES.ROLE_CSA || GetUserRole() == constants.ROLES.ROLE_OFFICER || GetUserRole() == constants.ROLES.ROLE_VENDOR || GetUserRole() == constants.ROLES.ROLE_AGENT || GetUserRole() == constants.ROLES.ROLE_ADMIN) {
                    //update data on firestore
                    let newData = {
                        ...data?.data,
                        access_token: GetAccessToken(),
                    }
                    // await updateUser(data.data)
                    await SetAuthUser(JSON.stringify(newData));
                }
                toast.success(data.message);
                history.push('/profile');
                setDisableButton(false)
            } else {
                toast.error(data.message);
                setDisableButton(false)
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    // const onSubmit = async (data) => {
    //     let params = {
    //         full_name: data.full_name.trim(),
    //         email: data.email,
    //         phone_number: data.phone_number,
    //         profile_image: '', // Placeholder for the profile image URL
    //     };

    //     setDisableButton(true);

    //     try {
    //         if (data.media.length > 0) {
    //             let uploadImage = await UploadImageToS3(data.media[0]);
    //             if (uploadImage && uploadImage.success) {

    //                 params.profile_image = uploadImage.data;
    //             } else {
    //                 toast.error(uploadImage.message);
    //                 setDisableButton(false);
    //                 return false;
    //             }
    //         }

    //         let updateProfileData = await UpdateProfile(params);

    //         if (updateProfileData.success) {
    //             if (GetUserRole() === constants.ROLES.ROLE_CSA || GetUserRole() === constants.ROLES.ROLE_VENDOR) {
    //                 let newData = {
    //                     user: updateProfileData.data,
    //                     access_token: GetAccessToken(),
    //                 };
    //                 await SetAuthUser(JSON.stringify(newData));
    //             }
    //             toast.success(updateProfileData.message);
    //             history.push('/profile');
    //         } else {
    //             toast.error(updateProfileData.message);
    //         }
    //     } catch (error) {
    //         setDisableButton(false);
    //         if (error.response && error.response.status === 401) {
    //             EmptyLocalStorage();
    //             history.push('/');
    //         } else {
    //             toast.error(error.response ? error.response.data.message : 'An error occurred.');
    //         }
    //     }
    //     setDisableButton(false);
    // };


    const handleChange = (event) => {
        setFileData(URL.createObjectURL(event.target.files[0]));
    }


    return (
        <div className={"EditProfile"}>
            <h3 className="page-heading">Edit Profile</h3>
            <hr />
            <Row>
                <Col xs={12} sm={12} md={8} lg={12} xl={12}>
                    <Form encType="multipart/form-data" className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <Row className={"mb-3 align-items-center"}>

                                <Col xs={12} sm={12} md={6} lg={12} xl={12} className="m-auto">
                                    <ul className={"list-inline list-variations mb-0"}>
                                        <li className={"list-inline-item d-flex mb-0"}>
                                            <img onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = constants.IMAGENOTFOUND;
                                            }} src={file ? file : defaultImage} width="50%" height="50%" />
                                        </li>
                                    </ul>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} className="mt-4">
                                    <Form.Group controlId="formFile" onChange={(e) => handleChange(e)}
                                        className="custom-file-upload mb-0">
                                        <Form.Label className={"toggleFileUpload mb-0"}>
                                            <UploadIcon />
                                            Upload Image
                                        </Form.Label>
                                        <Form.Control type="file"
                                            accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>


                        <Row>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="formFullName">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Full Name"
                                        name="full_name"
                                        {...register("full_name", {
                                            onChange: (e) => {
                                                setValue('full_name', e.target.value)
                                            },
                                            required: {
                                                value: "required",
                                                message: "Full name is required"
                                            },
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: "Invalid full name"
                                            },
                                        }
                                        )}
                                        maxLength={"255"} />
                                    <Form.Text className="text-muted validationText hasError">
                                        {errors.full_name && <Form.Text
                                            className="text-muted validationText hasError">{errors.full_name.message}</Form.Text>}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Email Address"
                                        readOnly
                                        {...register("email")}
                                        maxLength={"255"} />
                                    <Form.Text className="text-muted validationText hasError">
                                        {errors.email && <Form.Text
                                            className="text-muted validationText hasError">{errors.email.message}</Form.Text>}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="phone_number">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="tel"
                                        placeholder="Enter Phone Number"
                                        {...register("phone_number", {
                                            required: {
                                                value: "required",
                                                message: "Phone is required",
                                            },
                                            pattern: {
                                                value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                                                message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                                            },

                                            minLength: {
                                                value: 8,
                                                message: "Minimum length is 8",
                                            },
                                            maxLength: {
                                                value: 16,
                                                message: "Maximum length is 16",
                                            },
                                        })}
                                    />
                                    {errors.phone_number && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.phone_number.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                            <input type={"submit"} disabled={disableButton} className={"btn btn-green-theme w-100 mt-3"}
                                value={"Edit User"} />
                        </Col>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default EditProfile

