import {
    FetchRootFolders,
} from "../../services/InterlockReports/InterLockReports";


// InterLock Reports Integration starts from here
export const getRootFolder = async ({ client_id, setFolderData, documentType }) => {

    let params = {
        client: client_id,
        is_root_folder: true,
        type: documentType
    };
    let res = await FetchRootFolders(params);
    if (res.length > 0) {
        setFolderData(res);
    }
};