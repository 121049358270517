import moment from "moment";
import { toast } from "react-toastify";
import saveAs from "file-saver";

// Custom Imports
import {
  GetTestPdf,
} from "../../services/users.service";
import {
  EmptyLocalStorage,
} from "../../services/auth/auth.service";

const onGenerateReport = async ({ id, startDate, endDate, reportType, status, setDisableDownload, history }) => {

  let reportStartDate, reportEndDate;

  switch(reportType) {
    case 'day':
      reportStartDate = moment().startOf('day').format("YYYY-MM-DD");
      reportEndDate = moment().endOf('day').format("YYYY-MM-DD");
      break;
    case 'week':
      reportStartDate = moment().subtract(6, 'days').startOf('day').format("YYYY-MM-DD");
      reportEndDate = moment().endOf('day').format("YYYY-MM-DD");
      break;
    case 'month':
      reportStartDate = moment().startOf('month').format("YYYY-MM-DD");
      reportEndDate = moment().endOf('month').format("YYYY-MM-DD");
      break;
    default:
      reportStartDate = moment(startDate).format("YYYY-MM-DD");
      reportEndDate = moment(endDate).format("YYYY-MM-DD");
  }

  setDisableDownload(true);
  await GetTestPdf(
    id,
    reportStartDate,
    reportEndDate,
    status
  )
    .then((result) => {

      if (result.data.success) {
        toast.success(result.data.message);
        console.log("data: ", result.data.data.url);
        saveAs(result.data.data.url, result.data.data.name);
        setDisableDownload(false);
      } else {
        toast.error(result.message);
        setDisableDownload(false);
      }
    })
    .catch((error) => {
      setDisableDownload(false);
      if (error.response.status == 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        return toast.error(error.response.data.message);
      }
    });
};

export default onGenerateReport;