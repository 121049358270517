import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { constants, customStyles } from "../../utils/constants";
import moment from "moment";
import {
  CreateUser,
  DeleteUser,
  GetUsers,
  UpdateUser,
} from "../../services/users.service";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { BsCameraVideoFill } from "react-icons/bs";
import ThemeModal from "../../components/ThemeModal";

function Representatives() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [rows, setRows] = useState();
  const [keyword, setKeyword] = useState("");
  const clearInput = () => {
    setKeyword("");
  };
  const [imageModal, setImageModal] = useState({
    url: "",
    status: false,
  });
  useEffect(async () => {
    await getUsers();
  }, [page, perPage, keyword]);

  const getUsers = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_REPRESENTATIVE, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            setPage(result.current_page);
            setTotalRows(result.total);
            result.data.map((dt, index) => {
              rowData.push({
                id: dt.id,
                index: ++index,
                CategoryImg: dt.profile_image || constants.ClientImage,
                full_name: dt.full_name || "-",
                state: dt.state || "-",
                email: dt.email || "-",
                phone: dt.phone_number || "-",
                created_at: moment(dt.created_at).fromNow(),
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onUserDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      await DeleteUser(id)
        .then((result) => {
          if (result.success) {
            swal(result.message, {
              icon: "success",
            });
            getUsers();
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    });
  };

  const onEdit = (data) => {
    history.push("/manage-representatives/edit-representatives/" + data.id, {
      data: data,
    });
  };

  const ModalForImage = () => {
    return (
      <>
        <div className={"modalBodyContent"} style={{ textAlign: "center" }}>
          <img
            style={{
              objectFit: "cover",
              objectPosition: "center",
              textAlign: "center",
            }}
            src={imageModal.url}
            className="img-fluid"
          />
        </div>
      </>
    );
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={() => onEdit(data)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };
  const statusCheck = (status) => {
    if (status) {
      let statusClass = "status-badge status-" + status.toLowerCase();
      return <div className={statusClass}>{status}</div>;
    } else {
      return <div className={"status-badge status-undefined"}>{status}</div>;
    }
  };
  const State = () => {
    const Mystate = [{ id: 1, State: "Texas" }];

    return (
      <div className="users">
        {Mystate.map((user) => (
          <div className="user">{user.State}</div>
        ))}
      </div>
    );
  };
  const CurrentLocation = () => {
    const Location = [{ id: 1, Type: "Texas" }];

    return (
      <div className="users">
        {Location.map((user) => (
          <div className="user">{user.Type}</div>
        ))}
      </div>
    );
  };

  const [modalShow, setModalShow] = useState(false);

  const ModalForm = () => {
    return (
      <>
        <div>
          <video controls style={{ width: "100%", height: "auto" }}>
            <source src={constants.MyDemoVideo} type="video/mp4"></source>
          </video>
        </div>
      </>
    );
  };
  const VideoIcon = () => {
    return (
      <div>
        <BsCameraVideoFill onClick={() => setModalShow(true)} />
      </div>
    );
  };

  const columns = [
    {
      id: "CategoryImg",
      name: "Picture",
      width: "100px",
      selector: (rowData) => rowData.oid,
      format: (rowData) => (
        <div className="custom-img">
          <img
            onClick={() =>
              setImageModal({
                url: rowData.CategoryImg,
                status: true,
              })
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = constants.ClientImage;
            }}
            src={rowData.CategoryImg}
            className={"img-table img-thumbnail"}
            alt={"Image"}
          />
        </div>
      ),
    },

    {
      id: "full_name",
      name: "Representative Name",
      maxWidth: "160px",
      selector: (rowData) => rowData.full_name,
      format: (rowData) => (
        <NavLink
          className="text-dark text-decoration-none"
          to={`/manage-representatives/edit-representatives/${rowData.id}`}
        >
          {rowData.full_name}
        </NavLink>
      ),
    },
    {
      id: "email",
      name: "Phone Number",
      maxWidth: "160px",
      selector: (rowData) => rowData.phone,
      //   selector: CurrentLocation,
    },
    // {
    //     id: 'username',
    //     name: '',
    //     maxWidth: '160px',
    //     selector: rowData => rowData.username,
    // },
    {
      id: "email",
      name: "Email",
      selector: (rowData) => rowData.email,
    },
    // {
    //   id: "action",
    //   name: "Video",
    //   cell: VideoIcon,
    // },
    //     {
    //         id: "CategoryImg",
    //         selector: (rowData) => rowData.oid,

    //     // format: (rowData) => (
    //     // //   <img
    //     // //     onError={({ currentTarget }) => {
    //     // //       currentTarget.onerror = null; // prevents looping
    //     // //       currentTarget.src = constants.ClientImage;
    //     // //     }}
    //     // //     src={constants.DEMO_VIDEO}
    //     // //     className={"img-table img-thumbnail"}
    //     // //     alt={"Image"}
    //     // //   />
    //     // <video>
    //     //     <source src={constants.DEMO_VIDEO} type="video/mp4"></source>
    //     // </video>
    //     // ),
    //   },
    {
      id: "status",
      name: "State",
      selector: (rowData) => rowData.state,
    },
    // {
    //     id: 'phone',
    //     name: 'Phone',
    //     selector: rowData => rowData.phone,
    // },
    /*{
            id: 'created_at',
            name: 'Created At',
            selector: rowData => rowData.created_at,
        },*/
    {
      id: "action",
      name: "Action",
      cell: (rowData) => userActions(rowData),
    },
  ];

  const handlePageChange = async (page) => {
    await setPage(page);
  };

  return (
    <div className={"usersPage"}>
      <h3 className="page-heading">Manage Representatives</h3>
      <hr />
      <div className={"customOrderFilters"}>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} xl={5}>
            {/* <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                onChange={(e) => {
                  e.target.value.length > 0
                    ? setKeyword(e.target.value)
                    : setKeyword(null);
                }}
              />
            </InputGroup> */}
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1">
                <SearchIcon />
              </InputGroup.Text>
              <FormControl
                placeholder="Search"
                className="custom-search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={"255"}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
                style={{
                  borderRight: keyword ? "none" : "1px solid #ced4da",
                  borderTopRightRadius: keyword ? "0" : "5px",
                  borderBottomRightRadius: keyword ? "0" : "5px",
                }}
              />
              {keyword && (
                <InputGroup.Text
                  className="cross-icon"
                  id="basic-addon2"
                  onClick={clearInput}
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 6.48828L18 18.4883"
                      stroke="#817A8E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 6.48828L6 18.4883"
                      stroke="#817A8E"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7} xl={7}>
            <Row>
              <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
              <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <Link to={"/manage-representatives/add-representatives"}>
                  <Button className={"btn btn-green-theme h40 w-100"}>
                    <AddIconOutline /> Add New
                  </Button>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid custom-table-border mt-4">
        <DataTable
          columns={columns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => {
            setPage(page);
          }}
          onChangeRowsPerPage={(currentRowsPerPage) => {
            setPerPage(currentRowsPerPage);
          }}
          progressComponent={
            <BarLoader
              color={"#961b1a"}
              loading={loading}
              css={"marginTop: 10px"}
              height={"4"}
              width={"100%"}
            />
          }
        />
      </div>
      <ThemeModal
        // title={"Modify Schedule"}
        size={"lg"}
        content={<ModalForm />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />

      <ThemeModal
        title={""}
        size={"md"}
        content={<ModalForImage />}
        show={imageModal.status}
        onHide={() =>
          setImageModal({
            url: "",
            status: false,
          })
        }
      />
    </div>
  );
}

export default Representatives;
