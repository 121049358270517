import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Col, Dropdown, DropdownButton, FormControl, InputGroup, Row } from "react-bootstrap";
import { SearchIcon, MenuDots, } from "../../components/SvgIcons";
import { GetDeletedUsers, RestoreDeletedUser } from "../../services/users.service";
import { EmptyLocalStorage, GetAuthUser } from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import moment from "moment";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { constants, customStyles, rectSelectStyles, userTypeData } from "../../utils/constants";
import ThemeModal from "../../components/ThemeModal";
import Select from "react-select";
import swal from "sweetalert";

function DeletedUsers() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const current_user = GetAuthUser();
  const [imageModal, setImageModal] = useState({ url: "", status: false });
  const [selectedUserType, setSelectedUserType] = useState(null);

  useEffect(() => {
    getDeletedUsersFunc();
  }, [keyword, page, perPage, selectedUserType]);

  const getDeletedUsersFunc = async () => {
    setLoading(true);
    try {
      const result = await GetDeletedUsers(keyword, constants.ROLES.ROLE_USER, page, perPage, "", "", selectedUserType?.value);
      if (result.success) {
        const rowData = result.data.map((dt, index) => ({
          id: dt.id,
          user_short_id: dt.user_short_id,
          index: ++index,
          CategoryImg: dt.profile_image || constants.ClientImage,
          full_name: dt.full_name || "-",
          state: dt.state || "-",
          email: dt.email || "-",
          phone: dt.phone_number || "-",
          dob: dt.dob || "-",
          spnNo: dt.license || "-",
          program: dt.program || "-",
          county: dt.county || "-",
          officer: dt.officer_name || "-",
          representative: dt.representative || "-",
          created_at: moment(dt.created_at).fromNow(),
          user_type: dt?.user_type,
        }));
        setRows(rowData);
        setPage(result.current_page);
        setTotalRows(result.total);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        toast.error(error.response?.data?.message || error.message);
      }
    }
    setLoading(false);
  };

  const clearInput = () => {
    setKeyword("");
  };

  const ModalForImage = () => (
    <div className={"modalBodyContent"} style={{ textAlign: "center" }}>
      <img style={{ objectFit: "cover", objectPosition: "center", textAlign: "center" }} src={imageModal.url} className="img-fluid" />
    </div>
  );

  const onViewPage = (data) => {
    history.push(data?.user_type === 20 ? `/deleted-users/${data.id}` : `/deleted-users/${data.id}`);
  };

  const restoreDeleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to restore deleted this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        const params = {
          user: id
        }
        await RestoreDeletedUser(params)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              getDeletedUsersFunc();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={() => onViewPage(data)}>
          View User Details
        </Dropdown.Item>
        {console.log("delete user js", data?.id)}
        <Dropdown.Item as="button" onClick={() => { restoreDeleteUser(data?.id) }}>
          Restore User
        </Dropdown.Item>
      </DropdownButton>
    );
  };

  const columns = [
    { name: "S No.", width: "50px", selector: (rowData) => rowData.user_short_id },
    {
      name: "Picture",
      width: "100px",
      selector: (rowData) => rowData.oid,
      cell: (rowData) => (
        <div className="custom-img">
          <img
            onClick={() => setImageModal({ url: rowData.CategoryImg, status: true })}
            onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = constants.ClientImage; }}
            src={rowData.CategoryImg}
            className="img-table img-thumbnail w-100 h-100"
            alt="Image"
          />
        </div>
      ),
    },
    { name: "Full Name", maxWidth: "160px", selector: (rowData) => rowData.full_name, cell: (rowData) => <NavLink className="text-dark text-decoration-none text-center" to={rowData?.user_type === 10 ? `/deleted-users/${rowData.id}` : `/deleted-users/${rowData.id}`}>{rowData.full_name}</NavLink> },
    { name: "Date of Birth", selector: (rowData) => rowData.dob },
    { name: "Case# OR SPN", selector: (rowData) => rowData.spnNo },
    { name: "Program", selector: (rowData) => <div className="text-uppercase">{rowData.program}</div> },
    { name: "County", selector: (rowData) => rowData.county },
    { name: "Officer", selector: (rowData) => rowData.officer },
    { name: "Device", selector: (rowData) => rowData.user_type === 10 ? "Portable" : "Interlock" },
    { name: "Action", cell: (rowData) => userActions(rowData) },
  ];

  const onChangeUserType = (selectedOption) => {
    setSelectedUserType(selectedOption);
  };

  const memoizedColumns = useMemo(() => columns, []);

  return (
    <div className="usersPage">
      <h3 className="page-heading">Deleted Users</h3>
      <hr />
      <div className="customOrderFilters">
        <Row>
          <Col xs={12} md={4} lg={5}>
            <InputGroup className="mb-3 search-group">
              <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
              <FormControl
                placeholder="Search"
                className="custom-search"
                aria-label="Search"
                aria-describedby="Search"
                maxLength={255}
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                style={{ borderRight: keyword ? "none" : "1px solid #ced4da", borderTopRightRadius: keyword ? 0 : "5px", borderBottomRightRadius: keyword ? 0 : "5px" }}
              />
              {keyword && (
                <InputGroup.Text className="cross-icon" id="basic-addon2" onClick={clearInput}>
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 6.48828L18 18.4883" stroke="#817A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M18 6.48828L6 18.4883" stroke="#817A8E" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </InputGroup.Text>
              )}
            </InputGroup>
          </Col>
          <Col xs={12} md={8} lg={7}>
            <Row className="justify-content-end">
              <Col xs={12} md={5} lg={5}>
                <Select
                  name="userTypeSelect"
                  styles={rectSelectStyles}
                  onChange={onChangeUserType}
                  options={userTypeData}
                  isSearchable={false}
                  placeholder="Filter Users"
                  className="react-select-container mb-3"
                  classNamePrefix="react-select"
                  isClearable
                />
              </Col>

            </Row>
          </Col>
        </Row>
      </div>
      <div className="theme-dataGrid products-dataGrid custom-table-border mt-4">
        <DataTable
          columns={memoizedColumns}
          data={rows}
          progressPending={loading}
          customStyles={customStyles}
          selectableRows={false}
          striped
          highlightOnHover
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationPerPage={perPage}
          onChangePage={(page) => setPage(page)}
          onChangeRowsPerPage={(currentRowsPerPage) => setPerPage(currentRowsPerPage)}
          paginationRowsPerPageOptions={[30, 60, 90, 180, 270, 360]}
          progressComponent={<BarLoader color="#961b1a" loading={loading} css={"marginTop: 10px"} height={4} width="100%" />}
        />
      </div>
      <ThemeModal
        title="User Image"
        size="md"
        content={<ModalForImage />}
        show={imageModal.status}
        onHide={() => setImageModal({ url: "", status: false })}
      />
    </div>
  );
}

export default DeletedUsers;
