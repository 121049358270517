import React from 'react'
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { UpdateStatus } from "../../../services/csa/orders.service";
import { constants } from "../../../utils/constants";
import { EmptyLocalStorage, GetUserRole } from "../../../services/auth/auth.service";
import { useHistory } from "react-router";

const OrderDetails = ({ order_data, orderConfirm, setOrderConfirm }) => {
    const history = useHistory();
    const role = GetUserRole()
    const onOrderConfirm = (order_id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to confirm this order?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            if (willShip) {
                await updateOrder(order_id, { status: constants.ORDERSTATUS.CONFIRMED })
                swal("Your order has been marked as confirmed", {
                    icon: "success",
                });
            }
        });
    }

    const updateOrder = async (orderId, data) => {
        await UpdateStatus(orderId, data).then(async (data) => {
            if (data.success) {
                await setOrderConfirm(!orderConfirm);
                toast.success(data.message);
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            }
            else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const orderButton = (order_data) => {
        if (order_data.status == constants.ORDERSTATUS.ORDERED) {
            return (
                <Button className={"btn btn-green-theme w-100 mt-3"} type={"button"} onClick={() => {
                    onOrderConfirm(order_data.id)
                }}> Confirm </Button>
            )
        }
    }

    return (
        <div>
            <div className={"PanelBox"}>
                <h3>Order Details</h3>
                <hr />
                <ul className={"list-unstyled"}>
                    <li><b>Order Number</b> <span>{order_data.order_number}</span></li>
                    <li><b>Customer Name</b> <span>{order_data.customer_name}</span></li>
                    {
                        (role == constants.ROLES.ROLE_VENDOR) ?
                            <li><b>Agent Name:</b> <span>{order_data.agent_name}</span></li> : ''
                    }

                    <li><b>Status</b> <span>{constants.ORDERSTATUSKEY[order_data.status]}</span></li>
                    <li><b>Total Amount</b> <span>{order_data.total_amount}</span></li>
                    <li><b>Total Shipping Fees</b> <span>{order_data.total_shipping_fees}</span></li>
                    <li><b>Sales Tax</b> <span>{order_data.sales_tax}</span></li>
                    <li><b>Shipping Date</b> <span>{order_data.shipping_date}</span></li>
                    <li><b>Shipping Method</b> <span>{order_data.shipping_method}</span></li>
                    <li><b>Description:</b> {order_data.description}</li>
                </ul>
                {(role == constants.ROLES.ROLE_CSA) ? orderButton(order_data) : ''}
            </div>
        </div>
    )
}

export default OrderDetails
