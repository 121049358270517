import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import { CreateAgent } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";

function AddNewAgent(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [mediaRequiredError, setMediaRequiredError] = useState(false);
  const [files, setFiles] = useState([]);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [agentTypeId, setAgentTypeId] = useState("");
  const [disableField, setDisableField] = useState(true);
  const [selectedFile, setSelectedFile] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const { setValue } = useForm({
    mode: "onChange",
  });
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setDisableButton(true);
    let url = "";
    if (selectedFile) {
      if (
        selectedFile.type == "image/jpg" ||
        selectedFile.type == "image/png" ||
        selectedFile.type == "image/jpeg"
      ) {
        let uploadImage = await UploadImageToS3(selectedFile);
        if (uploadImage) {
          if (!uploadImage.success) {
            toast.error(uploadImage.message);
            return false;
          }

          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          // filename = file.name;
        }
        await CreateAgent({
          full_name: data.full_name.trim(),
          state: data.state,
          agent_type: data.agent_type,
          email: data.email.trim(),
          phone_number: data.phone_number.trim(),
          password: data.password.trim(),
          password_confirmation: data.password_confirmation.trim(),
          profile_image: url,
          device_type: "web",
          device_token: "agent",
          clients: [],
        })
          .then(async (data) => {
            if (data.success) {
              toast.success(data.message);
              history.push("/manage-agents");
            } else {
              setDisableButton(false);
              toast.error(data.message);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      } else {
        setDisableButton(false);
        return toast.error("Invalid file type.");
      }
    }
  };
  function CustomDropZone(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      onChange,
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png",
      maxFiles: 10,
      onDrop: (files) => {
        setValue("media", files);

        setMediaRequiredError(false);
      },
    });

    const acceptedFileItems = acceptedFiles.map((file, index) => (
      <li key={file.path} style={{ listStyle: "none", fontSize: "10px" }}>
        {index > 1 ? index + "Files Selected" : index + "File Selected"}
        {/*{file.path} - {file.size} bytes*/}
      </li>
    ));

    const thumbs = files.map((file) => (
      <div className={"thumb"} key={file.name}>
        <div className={"thumbInner"}>
          <img src={file.preview} className={"img"} />
        </div>
      </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    return (
      <div className="dropZoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input type="file" name="media" {...getInputProps()} />
          <UploadIcon />
          <p>Drag images or click to upload</p>
        </div>
        <aside className={"thumbsContainer"}>{thumbs}</aside>
        {/*{
                    acceptedFileItems.length > 0 ?
                        <div style={{marginLeft: '10px'}}>
                            <span style={{fontSize: '10px'}}>Selected files</span>
                            <p>{acceptedFileItems.length}</p>
                        </div> : ''
                }*/}

        {/*<aside>
                <h4>Accepted files</h4>
                <ul>{acceptedFileItems}</ul>
                <h4>Rejected files</h4>
                <ul>{fileRejectionItems}</ul>
            </aside>*/}
      </div>
    );
  }
  const handleChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Create Agent</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
            {/* <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group>
                  <Form.Label className={"form-label"}>
                    Image<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div className={"box-dotted"}>
                    <CustomDropZone ref={{ ...register("media") }} />
                    {mediaRequiredError ? (
                      <Form.Text className="text-muted validationText hasError">
                        Media is required
                      </Form.Text>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group>
                  <Form.Label className={"form-label"}>
                    Video<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div className={"box-dotted"}>
                    <CustomDropZone ref={{ ...register("media") }} />
                    {mediaRequiredError ? (
                      <Form.Text className="text-muted validationText hasError">
                        Video is required
                      </Form.Text>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row> */}
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row className={"mb-3 align-items-center"}>
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Form.Group
                      controlId="formFile"
                      onChange={(e) => handleChange(e)}
                      className="custom-file-upload mb-0"
                    >
                      <Form.Label className={"toggleFileUpload mb-0"}>
                        <UploadIcon />
                        Upload Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("profile_image", {
                          required: {
                            value: "required",
                            message: "Image is required",
                          },
                        })}
                      />
                      {errors.profile_image && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.profile_image.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ul className={"list-inline list-variations mb-0"}>
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                          }}
                          src={file ? file : defaultImage}
                          width="50%"
                          height="50%"
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Invalid full name",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="agent_type">
                  <Form.Label>Agent Type</Form.Label>

                  <select
                    class={"form-select"}
                    {...register("agent_type", {
                      required: {
                        value: "required",
                        message: "Agent type is required",
                      },
                    })}
                    onChange={(e) => {
                      setAgentTypeId(e.target.value);
                      setDisableField(true);
                    }}
                    aria-label="Default Filters"
                    value={agentTypeId}
                  >
                    <option disabled={true}>Select Agent Type</option>
                    <option value="2">Lead Agent</option>
                    <option value="1">Agent</option>
                  </select>

                  {errors.agent_type && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.agent_type.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              {/* <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="Current_Location">
                  <Form.Label>Current Location</Form.Label>
                  <Form.Control
                    placeholder="Current Location"
                    {...register("Current_Location", {
                      required: {
                        value: "required",
                        message: "Current Location is required",
                      },
                      minLength: {
                        value: 4,
                        message: "Minimum length is 6",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.Current_Location && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.Current_Location.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col> */}
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                        message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                      },

                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              {/* <Row>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date Of Birth</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Date Of Birth"
                                        max={format(new Date(), 'yyyy-MM-dd')}
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "dob is required"
                                            },
                                        })} type="date"/>
                                    {errors.dob && <Form.Text
                                        className="text-muted validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row> */}
              {/*<Row>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "company name is required"
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "Minimum length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Maximum length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "business type is required"
                                                },
                                                minLength: {
                                                    value: 6,
                                                    message: "Minimum length is 6"
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: "Maximum length is 255"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>*/}

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Enter State"
                    id={"formSignUpName"}
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      {...register("password", {
                        required: {
                          value: "required",
                          message: "Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <Form.Control
                      placeholder="Enter Password Again"
                      // disabled={confirmPass}
                      {...register("password_confirmation", {
                        required: {
                          value: "required",
                          message: "Confirm Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                            value === password.current ||
                            "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      // disabled={confirmPass}
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Create Agent"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddNewAgent;
