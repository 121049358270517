import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { PiUsersFourThin } from "react-icons/pi";
import { GetDashboard } from "../../../services/admin/dashboard.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../../services/auth/auth.service";
import { useHistory } from "react-router-dom";
import DashboardStat from "../../../components/DashboardStat";
import { RepesentativeIcon, TotalCases, TotalOfficerAgents, TotalOfficerIcon } from "../../../components/SvgIcons";
import { Link } from "react-router-dom/cjs/react-router-dom";
import BarCharts from "../../../components/Graphs/BarCharts";
import DonutChart from "../../../components/Graphs/DonutChart";
import { getDashboardData } from "../../../services/dashboard/dashboard.service";


const InitialStats = {
  users_count: "0",
  cases_count: "0",
  representatives_count: "0",
  officers_count: "0",
  agents_count: "0",
};

const Admin = ({ RoleId }) => {
  const history = useHistory();
  const [stats, setStats] = useState(InitialStats);
  const [dashboardData, setDashboardData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = { role_id: RoleId };
        const response = await GetDashboard(params);

        if (response.success) {
          const { users_count, cases_count, representatives_count, officers_count, agents_count } = response.data;
          setStats({ users_count, cases_count, representatives_count, officers_count, agents_count });
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(error.response ? error.response.data.message : "An error occurred");
        }
      }
    };

    fetchData();
  }, [RoleId, history]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDashboardData();
        if (response) {
          setDashboardData(response?.data);
        }

      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();

    // Clean-up function if needed
    return () => {
      // Perform any clean-up actions here if needed
    };
  }, []);

  const renderNavLink = (to, variant, title, icon, countMain) => (
    <>
      < div className="dashboard-stats-item" >
        { }
        <Link className="p-0" to={`${to}`} style={{
          display: 'block',
          color: '#0d6efd',
          textDecoration: 'none',
          transition: 'color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out',
        }} activeClassName="active">
          <DashboardStat variant={variant} title={title} icon={icon} countMain={countMain} />
        </Link>
      </div>
    </>
  );

  return (
    <>
      <div className="dashboard-stats-wrapper">
        {/* Rendered NavLink components */}
        {renderNavLink("/manage-user", "theme-green", "Total Users", <PiUsersFourThin />, stats?.users_count)}
        {renderNavLink("/manage-representatives", "theme-success", "Total Representatives", <RepesentativeIcon />, stats?.representatives_count)}
        {renderNavLink("/manage-officer", "gradient-yellow", "Total Officers", <TotalOfficerIcon />, stats?.officers_count)}
        {renderNavLink("/manage-agents", "gradient-pink", "Total Agents", <TotalOfficerAgents />, stats?.agents_count)}
        {renderNavLink("#", "theme-danger", "Total Cases", <TotalCases />, stats?.cases_count)}
      </div>

      {/* Graph */}
      <Row className="align-items-center">
        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
          <BarCharts dashboardData={dashboardData} />
        </Col>
        {(dashboardData && dashboardData?.clients_count?.length) && <Col xs={12} sm={12} md={6} lg={{ span: 5, offset: 1 }} xl={4} className="chart-border">
          <h6 className="text-bold pt-4">All Users</h6>
          <DonutChart dashboardData={dashboardData} />
        </Col>
        }
      </Row >
    </>
  );
};

export default Admin;
