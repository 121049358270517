import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import 'swiper/css/navigation'; // Import Swiper navigation styles
import 'swiper/css/pagination'; // Import Swiper pagination styles
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, Image, Modal } from "react-bootstrap";
import { TemperedUsers } from '../../services/users.service';
import { EmptyLocalStorage } from '../../services/auth/auth.service';
import { toast } from 'react-toastify';
import { temperdStatus } from '../../utils/constants';
import { constants } from "../../utils/constants";

const TemperedUsersModal = ({ data, show, onHide, onUpdateRow }) => {
    let history = useHistory();
    const [report_id, setReport_id] = useState(null);
    const [choice, setChoice] = useState(null);
    useEffect(() => {
        setReport_id(data?.id)
    }, [choice, show])

    //function that is on click
    const handleButtonClick = (mark) => {
        setChoice(mark);
        temperedUsers(report_id, mark)
    };

    const temperedUsers = async (report_id, mark) => {
        const params = {
            report_id: report_id,
            choice: mark
        }
        await TemperedUsers(params).then((data) => {
            if (data.success) {
                toast.success(data.message);
                onHide();
                onUpdateRow({
                    ...data.data,
                    is_cta: constants.REPORT_FAILED_STATUS.includes(data.data.status_value) && data.data.bac_status === null
                });
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage();
                history.push("/");
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    SwiperCore.use([Navigation, Pagination]);
    return (
        <Modal
            show={show}
            onHide={onHide}
            size={"lg"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`customModal temperedUsersModal`}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Possible Tampered Users
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><b>User Name:</b>&nbsp;{data?.full_name}</p>
                <p><b>User status:</b>&nbsp;{data?.status}</p>
                <div className="d-flex align-items-center justify-content-center gap-4 mb-3">
                    <div className="d-flex justify-content-center align-items-center flex-column gap-1">
                        <div className="temperedImage">
                            <Image width={300} src={data?.init_picture} />
                        </div>
                        <p className='fw-bold m-0'>Initial Picture</p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column gap-1">
                        <div className="temperedImage">
                            <Image width={300} src={data?.post_picture} />
                        </div>
                        <p className='fw-bold m-0'>Post Picture</p>
                    </div>
                </div>
                <div className="d-flex justify-content-center gap-2 align-items-center flex-wrap tempered-btn">
                    <Button variant="danger" className='w-100' onClick={() => handleButtonClick(temperdStatus?.MARK_TEMPERING_VERIFIED)}>Tamper Verified</Button>
                    <Button variant="success" className='w-100' onClick={() => handleButtonClick(temperdStatus?.MARK_PASS)}>Mark as Passed</Button>
                </div>
            </Modal.Body>
        </Modal >
    )
}

export default TemperedUsersModal;