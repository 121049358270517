import React, { useEffect, useState } from 'react'
import { Route, Redirect } from "react-router-dom"
import Header from '../components/Header'
import SideBar from '../components/SideBar'
import { Container, Row, Col } from 'react-bootstrap'
import { EmptyLocalStorage, GetAuthUser, GetToken } from '../services/auth/auth.service'
import UserContext from "../hooks/UserContext";
import { GetProfile } from "../services/users.service";
import { toast } from "react-toastify";
import { updateUser } from "../services/firebase.service";
import { useHistory } from 'react-router-dom';

function DashboardLayout({ children }) {
    const history = useHistory()
    const [user, setUser] = useState('Profile');
    const [changeUser, setChangeUser] = useState(true);

    useEffect(() => {
        getProfile();
    }, [])

    const getProfile = async () => {
        await GetProfile().then(async (result) => {
            if (result.success) {
                if (result.data) {
                    let dt = result.data;
                    if (dt) {
                        await setUser(dt.full_name)
                    }
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            console.log(error);
            return toast.error(error.response.data.message);
        })
    }

    return (
        <div>
            <div className="dashboard_layout_wrapper">
                <UserContext.Provider value={user}>
                    <Header />
                </UserContext.Provider>
                <div className='d-flex'>
                    <aside
                        className={"d-none d-md-block sidebar-nav vh-100 mt-4 p-1"}>
                        <SideBar />
                    </aside>
                    <main className={"w-100"}>
                        <div className={"main_content_wrapper mb-5"}>
                            {children}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

const DashboardLayoutRoute = ({ isAuth, component: Component, ...rest }) => {
    isAuth = Boolean(GetToken());
    return (
        <>
            <Route
                {...rest}
                render={(matchProps) =>
                    isAuth ? (
                        <DashboardLayout>
                            <Component {...matchProps} />
                        </DashboardLayout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: matchProps.location },
                            }}
                        />
                    )
                }
            />
        </>
    );
};

export default DashboardLayoutRoute
