import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GetUserById, UpdateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";

function EditOfficer(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);

  const {
    register,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });


  useEffect(() => {
    const fetchData = async () => {
      const id = props.match.params.id;
      try {
        const result = await GetUserById(id);
        if (result.success && result.data) {
          setData(result.data);
          setDefaultImage(result.data.profile_image || constants.USERIMAGENOTFOUND);
          setValue("phone_number", result.data.phone_number);
          setValue("state", result.data.state);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(error.response ? error.response.data.message : "An error occurred");
        }
      }
    };

    fetchData();
  }, [props.match.params.id, setValue, history]);

  const onSubmit = async (formData) => {
    setDisableButton(true);
    try {
      let url = "";
      if (formData.media.length > 0) {
        const file = formData.media[0];
        if (file.type === "image/jpg" || file.type === "image/png" || file.type === "image/jpeg") {
          const uploadImage = await UploadImageToS3(file);
          if (uploadImage.success) {
            url = uploadImage.data;
          } else {
            toast.error(uploadImage.message);
            setDisableButton(false);
            return;
          }
        } else {
          toast.error("Invalid file type.");
          setDisableButton(false);
          return;
        }
      } else {
        url = defaultImage;
      }

      const id = props.match.params.id;
      const userData = {
        state: formData.state,
        phone_number: formData.phone_number,
        profile_image: url,
      };

      if (formData.password && formData.password_confirmation) {
        userData.password = formData.password;
        userData.password_confirmation = formData.password_confirmation;
      }

      const result = await UpdateUser(id, userData);
      if (result.success) {
        toast.success(result.message);
        history.push("/manage-officer");
      } else {
        toast.error(result.message);
        setDisableButton(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/");
      } else {
        toast.error(error.response ? error.response.data.message : "An error occurred");
      }
      setDisableButton(false);
    }
  };



  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };



  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">
        <span className="custom-color-maroon">Edit Officer -</span>{" "}
        {data.full_name}
      </h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form
            className="formAddUser"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="mb-3 justify-content-center align-items-center">
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group
                  controlId="formFile"
                  onChange={(e) => handleChange(e)}
                  className="custom-file-upload mb-0"
                >
                  <Form.Label className="toggleFileUpload mb-0">
                    <UploadIcon />
                    Upload Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    {...register("media")}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <ul className="list-inline list-variations mb-0">
                  <li className="list-inline-item d-flex justify-content-between mb-0">
                    <img
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = constants.IMAGENOTFOUND;
                      }}
                      src={file ? file : defaultImage}
                      width="50%"
                      height="50%"
                    />
                  </li>
                </ul>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                        message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Current Location"
                    defaultValue={data.state}
                    autoComplete="off"
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    {...register("password", {
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                    })}
                  />
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="password_confirmation">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    {...register("password_confirmation", {
                      validate: (value) =>
                        value === watch("password") || "Passwords do not match",
                    })}
                  />
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <input
                  type="submit"
                  className="btn btn-green-theme w-100 mt-3"
                  disabled={disableButton}
                  value="Edit Officer"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default EditOfficer;
