import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { FcOpenedFolder } from "react-icons/fc";
import { BsFiletypePdf } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import { DeleteRootFolder } from "../../services/InterlockReports/InterLockReports";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { EmptyLocalStorage, GetUserRole } from "../../services/auth/auth.service";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";



const SubFolder = ({
  data,
  onClickMainFolder,
  handleAddFields,
  setFolderArr,
  setSubFolderDelete,
  subFolderDelete
}) => {

  const [matchRole, setMatchRole] = useState(null);

  //   <FaFileAlt />
  const handleSubFolder = () => {
    setFolderArr([]);
    // handleAddFields();
  };

  const downloadPdf = (fileData) => {
    fetch(fileData?.file_url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${fileData?.name}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      });
  };

  let history = useHistory();

  // ============== Delete Folder =================

  const handleDeleteFolder = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete folder API
      await DeleteRootFolder(id)
        .then((result) => {
          if (result.success) {
            swal(result.message, {
              icon: "success",
            });

            setSubFolderDelete(!subFolderDelete)


            // data
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    });
  };

  // ========= UseEffects =================
  useEffect(() => {
    let role = GetUserRole();
    setMatchRole(role);
  }, []);

  return (
    <div style={{ padding: "10px" }}>
      {data.length > 0 ? (
        <Row>
          {data &&
            data?.map((v, i) => {
              return (
                <Col
                  xs={6}
                  sm={4}
                  md={2}
                  lg={2}
                  key={v.id}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {v?.category == 10 ? (
                    <div className="position-relative w-100 folderWrapperBox">
                      <div
                        variant="link"
                        style={{ textDecoration: "none", color: "black" }}
                        onClick={() => {
                          onClickMainFolder(v);
                          handleSubFolder();
                        }}
                        className="d-flex flex-column justify-content-center align-items-center p-0 folder-box"
                      >
                        <FcOpenedFolder style={{ fontSize: "50px" }} />
                        <p>{v?.name}</p>
                      </div>
                      {matchRole == constants.ROLES.ROLE_ADMIN || matchRole == constants.ROLES.ROLE_AGENT ? <div className="iconDelete position-absolute top-0 right-0" onClick={() => handleDeleteFolder(v?.id)}>
                        <TiDelete />
                      </div> : null}
                    </div>
                  ) : (
                    <div>
                      <div className="folderCreation">
                        <div className="position-relative w-100 folderWrapperBox">
                          <div
                            variant="link"
                            style={{ textDecoration: "none", color: "black" }}
                            onClick={() => downloadPdf(v)}
                            className="d-flex flex-column justify-content-center align-items-center p-0 folder-box"
                          >
                            <BsFiletypePdf style={{ fontSize: "50px" }} />
                            <p>{v?.name}</p>
                          </div>
                          {matchRole == constants.ROLES.ROLE_ADMIN || matchRole == constants.ROLES.ROLE_AGENT ? <div className="iconDelete position-absolute top-0 right-0" onClick={() => handleDeleteFolder(v?.id)}>
                            <TiDelete />
                          </div> : null}
                        </div>
                      </div>

                    </div>
                  )}
                </Col>
              );
            })}
        </Row>
      ) : (
        <div style={{ textAlign: "center" }}>No data found</div>
      )}
    </div>
  );
};

export default SubFolder;
