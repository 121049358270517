import { Button, Form, Spinner, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { FcOpenedFolder } from "react-icons/fc";
import { FaFolderPlus, FaPlusCircle } from "react-icons/fa";
import { PiArrowCircleLeftThin } from "react-icons/pi";
import { TiDelete } from "react-icons/ti";
import { constants } from "../../utils/constants";
import InterLockTableData from "../../views/pages/InterLockTableData";
import SubFolder from "../../views/pages/SubFolder";
import {
    AddIconOutline,
    SearchIcon,
    ViolatedFolderIcon,
} from "../../components/SvgIcons";
import { toast } from "react-toastify";
import {
    EmptyLocalStorage,
    GetUserRole
} from "../../services/auth/auth.service";
import swal from "sweetalert";
import {
    CreateRootFolders,
    DeleteRootFolder,
    FetchRootFolders,
    getPreviousFolders,
    getRecentUploadListing,
} from "../../services/InterlockReports/InterLockReports";
import { UploadImageToS3 } from "../../utils/helpers";
import { getRootFolder } from "../../services/InterlockReports/folderStructure"
import React, { useState, useEffect, useCallback, useRef } from "react";
import debounce from "lodash.debounce";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";


const InterLockFolders = ({ id, history, fromdDocuments, documentType }) => {

    const [matchRole, setMatchRole] = useState(null);
    const [mainFolderLoading, setMainFolderLoading] = useState(false);
    const [subFolderDelete, setSubFolderDelete] = useState(false)
    const [inputFields, setInputFields] = useState([{ value: "" }]);
    const [searchTerm, setSearchTerm] = useState("");
    const [headerFolderData, setHeaderFolderData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [backFolder, setBackFolder] = useState([]);
    const [parentId, setParentId] = useState(null);
    const [renderRootFolderOnDelete, setRenderRootFolderOnDelete] = useState(false);
    const [tableListingData, setTableListingData] = useState(null);
    const [folderRender, setFolderRender] = useState(0);
    const [uploadDocument, setUploadDocument] = useState(null);
    const [modalShowFileUploader, setModalShowFileUploader] = useState(false);
    const [modalShowSubFolder, setModalShowSubFolder] = useState(false);
    const [modalShowFolder, setModalShowFolder] = useState(false);
    const [loadingSubFolder, setLoadingSubFolder] = useState(false);
    const [inSubFolderMainFolderId, setInSubFolderMainFolderId] = useState(null);
    const [deleteFolderId, setDeleteFolderId] = useState(inSubFolderMainFolderId);
    const [mainFolderData, setMainFolderData] = useState(null);
    const [renderSubFolder, setRenderSubFolder] = useState(false);
    const [folderData, setFolderData] = useState(null);
    const [folderArr, setFolderArr] = useState(null);
    const [parameters, setParameters] = useState({
        folder_id: mainFolderData?.id,
        client_id: id,
    });
    const fileInputRef = useRef(null);


    const {
        register,
        watch,
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
    });

    // Sub folder delete
    const debouncedHandleSearch = debounce(async (newSearchTerm) => {
        let params = {
            client: id,
            is_root_folder: true,
            type: documentType,
            search: newSearchTerm,
        };
        let res = await FetchRootFolders(params);
        if (res.length > 0) {
            setFolderData(res);
        } else {
            setFolderData(null);
        }
    }, 500);

    const debounceRequest = useCallback(
        (newSearchTerm) => debouncedHandleSearch(newSearchTerm),
        []
    );


    const handleBackButton = async (data) => {
        let response = await getPreviousFolders(parameters);

        if (response[0]?.is_root_folder == true) {
            setFolderArr(response);
            setRenderRootFolderOnDelete(!renderRootFolderOnDelete)
            setParentId(null);
            setMainFolderData(null);
            setRenderSubFolder(false);
        } else {
            setDeleteFolderId(response[0]?.parent_folder)
            setParentId(response[0]?.parent_folder);
            setMainFolderData({
                name: response[0]?.parent_folder_name,
                violation: response[0]?.violation,
            });
            setFolderArr(response);
        }
    };

    const handleChangeSearch = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        debounceRequest(newSearchTerm);
    };

    // ============== Delete Folder =================

    const handleDeleteFolder = (id, history) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this folder?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete folder API
            await DeleteRootFolder(id)
                .then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: "success",
                        });

                        setRenderRootFolderOnDelete(!renderRootFolderOnDelete)
                    } else {
                        toast.error(result.message);
                    }
                })
                .catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage();
                        history.push("/");
                    } else {
                        return toast.error(error.response.data.message);
                    }
                });
        });
    };

    const onUploadFolder = async (data) => {
        if (data?.folder_name != "") {
            let obj = {
                client: id,
                name: data?.folder_name,
                category: 10,
                parent_folder: null,
                is_root_folder: true,
            };

            let res = await CreateRootFolders(obj);
            setFolderRender(folderRender + 1);
            // let obj = {
            //   date: "Nov 2023",
            //   folderName: data?.folder_name,
            //   type: "folder",
            // };
            // setFolderArr([...folderArr, obj]);
            setModalShowFolder(false);
        }
    };

    const onUploadFile = async (data) => {
        let documentUrl = "";
        const myFile = new File([uploadDocument], uploadDocument.name, {
            type: uploadDocument.type,
        });
        let uploadDocumentS3 = await UploadImageToS3(myFile);
        if (uploadDocumentS3) {

            if (!uploadDocumentS3.success) {
                toast.error(uploadDocumentS3.message);
                return false;
            }

            // content_type = constants.CONTENTTYPE[file.type];
            documentUrl = uploadDocumentS3.data;
            // filename = file.name;
        }

        let fileName = uploadDocument?.name?.replace(/\.[^/.]+$/, "");
        let obj = {
            client: id,
            name: fileName,
            category: 5,
            parent_folder: mainFolderData?.id,
            file_url: documentUrl,
        };

        if (documentUrl != "") {
            let res = await CreateRootFolders(obj);
            if (res) {
                let params = {
                    parent_folder: inSubFolderMainFolderId,
                    type: documentType,
                };
                let res = await FetchRootFolders(params);
                setFolderArr(res);
                setRenderSubFolder(true);
                setFolderRender(folderRender + 1);
                setLoadingSubFolder(false);
                toast.success("File Uploaded Successfully");
            }
            setUploadDocument(null);
            setModalShowFileUploader(false);
        }
    };

    const onUploadSubFolder = async (data) => {
        setLoadingSubFolder(true);
        if (data?.folder_name != "") {
            let obj = {
                client: id,
                name: data?.folder_name,
                category: 10,
                parent_folder: mainFolderData?.id,
                is_root_folder: false,
            };

            let res = await CreateRootFolders(obj);
            if (res) {
                let params = {
                    parent_folder: inSubFolderMainFolderId,
                    type: documentType
                };
                let res = await FetchRootFolders(params);
                setFolderArr(res);
                setRenderSubFolder(true);
                setFolderRender(folderRender + 1);
                setLoadingSubFolder(false);
            }
            setModalShowSubFolder(false);
            // let obj = {
            //   date: "Nov 2023",
            //   folderName: data?.folder_name,
            //   type: "folder",
            // };
            // setFolderArr(res);
        }
    };


    const AddFileForm = () => {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onUploadFile)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="file_name">
                                    <Form.Label>File Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter File Name"
                                        {...register("file_name", {
                                            required: {
                                                value: "required",
                                                message: "file Name is required",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Minimum length is 3",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25",
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.file_name && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.file_name.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <input
                                    type={"submit"}
                                    className={"btn btn-green-theme w-100 mt-3"}
                                    value={"Upload"}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    };

    const AddSubFolderForm = () => {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form
                        className="formAddUser"
                        onSubmit={handleSubmit(onUploadSubFolder)}
                    >
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="folder_name">
                                    <Form.Label>Folder Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Folder Name"
                                        {...register("folder_name", {
                                            required: {
                                                value: "required",
                                                message: "Folder Name is required",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Minimum length is 3",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25",
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.folder_name && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.folder_name.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <input
                                    type={"submit"}
                                    className={"btn btn-green-theme w-100 mt-3"}
                                    value={"Upload"}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    };

    const AddFolderForm = () => {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Form className="formAddUser" onSubmit={handleSubmit(onUploadFolder)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="folder_name">
                                    <Form.Label>Folder Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Folder Name"
                                        {...register("folder_name", {
                                            required: {
                                                value: "required",
                                                message: "Folder Name is required",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Minimum length is 3",
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25",
                                            },
                                        })}
                                        type="text"
                                    />
                                    {errors.folder_name && (
                                        <Form.Text className="text-muted validationText hasError">
                                            {errors.folder_name.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <input
                                    type={"submit"}
                                    className={"btn btn-green-theme w-100 mt-3"}
                                    value={"Upload"}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    };

    const onClickMainFolder = async (data) => {
        setMainFolderData(null);
        setMainFolderLoading(true);

        setInSubFolderMainFolderId(data?.id);
        if (data) {
            let params = {
                parent_folder: data?.id,
                type: documentType
            };
            let res = await FetchRootFolders(params);
            setMainFolderData(data);
            setMainFolderLoading(false);
            let arr = [];
            arr.push(data);
            setBackFolder([...backFolder, data?.id]);
            setHeaderFolderData([...headerFolderData, data]);
            setFolderArr(res);
            setRenderSubFolder(true);
        }
    };


    const handleAddFields = (setInputFields) => {
        const newInputFields = [{ value: "" }];
        setInputFields(newInputFields);
    };

    const handleChangeSubFolderFile = (e, setUploadDocument) => {
        setUploadDocument(e.target.files[0]);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };


    const getResultLisitng = async () => {
        let params = {
            client_id: id,
            type: documentType
        };
        let res = await getRecentUploadListing(params);
        if (res.length > 0) {
            setTableListingData(res);
        }
    };

    useEffect(() => {
        getRootFolder({ client_id: id, setFolderData, documentType });
        getResultLisitng();
    }, []);

    useEffect(() => {
        if (uploadDocument !== null) {
            onUploadFile();
        }
    }, [uploadDocument]);


    useEffect(() => {
        if (folderRender !== 0) {
            getRootFolder({ client_id: id, setFolderData, documentType });
            getResultLisitng();
        }
    }, [folderRender]);

    useEffect(() => {
        getRootFolder({ client_id: id, setFolderData, documentType });
        getResultLisitng();
    }, [renderRootFolderOnDelete]);

    useEffect(() => {
        setDeleteFolderId(inSubFolderMainFolderId)
    }, [inSubFolderMainFolderId]);

    useEffect(() => {
        if (parentId != null) {
            setParameters({
                folder_id: parentId,
                client_id: id,
            });
        } else {
            setParameters({
                folder_id: mainFolderData?.id,
                client_id: id,
            });
        }
    }, [parentId, mainFolderData]);

    useEffect(() => {
        let role = GetUserRole();
        setMatchRole(role);
    }, []);


    useEffect(async () => {
        if (deleteFolderId != null) {
            let params = {
                parent_folder: deleteFolderId,
                type: documentType
            };
            let res = await FetchRootFolders(params);
            setFolderArr(res);
        }

    }, [subFolderDelete])



    useEffect(() => {
        setCurrentIndex(backFolder.length - 1);
    }, [backFolder]);


    return <>
        {renderSubFolder ? (
            <>
                {inputFields?.map((val, ind) => {
                    return (
                        <div key={ind}>
                            <div className="subFolderMainDiv">
                                <div>
                                    <Button
                                        variant="link"
                                        style={{ textDecoration: "none", color: "black" }}
                                        onClick={handleBackButton}
                                    >
                                        <PiArrowCircleLeftThin style={{ fontSize: "30px" }} />
                                    </Button>
                                </div>

                                {mainFolderLoading ? (
                                    <Spinner animation="border" variant="dark" />
                                ) : (
                                    <>
                                        <div>
                                            <FcOpenedFolder style={{ fontSize: "50px" }} />
                                        </div>
                                        <div>
                                            <span>
                                                <b>{mainFolderData?.name}</b>
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>

                            {(matchRole === constants.ROLES.ROLE_ADMIN ||
                                matchRole === constants.ROLES.ROLE_AGENT) && (
                                    <div className="AddBtnFileDiv">
                                        {!fromdDocuments &&
                                            <div>
                                                <Form.Group
                                                    controlId="formFolder"
                                                    onClick={() => setModalShowSubFolder(true)}
                                                    className="custom-file-upload mb-0"
                                                >
                                                    <Form.Label className="toggleFileUpload mb-0 d-flex align-items-center">
                                                        <FaFolderPlus
                                                            style={{
                                                                fontSize: "25px",
                                                                verticalAlign: "bottom",
                                                            }}
                                                        />
                                                        Add A Folder
                                                    </Form.Label>
                                                </Form.Group>
                                            </div>
                                        }
                                        <div>
                                            <Form.Group controlId="formFile" className="custom-file-upload mb-0">
                                                <Form.Label className="toggleFileUpload mb-0 d-flex align-items-center">
                                                    <FaPlusCircle
                                                        style={{
                                                            fontSize: "25px",
                                                            verticalAlign: "bottom",
                                                        }}
                                                    />
                                                    Add A File
                                                </Form.Label>
                                                <Form.Control
                                                    type="file"
                                                    accept=".pdf"
                                                    onChange={(e) => handleChangeSubFolderFile(e, setUploadDocument)}
                                                    ref={fileInputRef}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                )}

                            <div className="SubFolderComp">
                                {loadingSubFolder ? (
                                    <div style={{ textAlign: "center" }}>Loading...</div>
                                ) : (
                                    <SubFolder
                                        data={folderArr}
                                        setFolderArr={setFolderArr}
                                        onClickMainFolder={onClickMainFolder}
                                        handleAddFields={() => handleAddFields(setInputFields)}
                                        setSubFolderDelete={setSubFolderDelete}
                                        subFolderDelete={subFolderDelete}
                                    />
                                )}
                            </div>
                        </div>
                    );
                })}
            </>
        ) : (
            <div className="usersPage">
                <Row>
                    <Col>
                        <h3 className="page-heading">Calibration and Reset Reports</h3>
                    </Col>
                </Row>
                <hr />

                {!fromdDocuments &&
                    <div className="customOrderFilters">
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                                <InputGroup className="mb-3 search-group">
                                    <InputGroup.Text id="basic-addon1">
                                        <SearchIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Search"
                                        aria-label="Search"
                                        aria-describedby="Search"
                                        maxLength={255}
                                        onChange={handleChangeSearch}
                                    />
                                </InputGroup>
                            </Col>
                            {(matchRole === constants.ROLES.ROLE_ADMIN ||
                                matchRole === constants.ROLES.ROLE_AGENT) && (
                                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                                                <Button
                                                    className="btn btn-green-theme h40 w-100"
                                                    onClick={() => setModalShowFolder(true)}
                                                >
                                                    <AddIconOutline /> Add New
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                        </Row>
                    </div>
                }

                <Row>
                    <Col xs={12} sm={12} md={12} lg={7} className="folderHolderContainer">
                        <div className="folder-wrapper">
                            {folderData ? (
                                <Row style={{ padding: "10px" }}>
                                    {folderData.map((v, i) => (
                                        // if folder type is not indication of 20 = Documents
                                        v.type == 10 && !fromdDocuments ? <Col
                                            xs={6}
                                            sm={4}
                                            md={2}
                                            lg={4}
                                            xl={2}
                                            style={{ display: "flex", justifyContent: "center" }}
                                            key={i}
                                        >
                                            <div className="position-relative w-100 folderWrapperBox">
                                                <div
                                                    variant="link"
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    onClick={() => onClickMainFolder(v)}
                                                    className="d-flex flex-column justify-content-center align-items-center p-0 folder-box"
                                                >
                                                    {v?.violation ? (
                                                        <ViolatedFolderIcon style={{ fontSize: "50px" }} />
                                                    ) : (
                                                        <FcOpenedFolder style={{ fontSize: "50px" }} />
                                                    )}
                                                    <p>{v?.name}</p>
                                                </div>
                                                {(matchRole === constants.ROLES.ROLE_ADMIN ||
                                                    matchRole === constants.ROLES.ROLE_AGENT) && (
                                                        <div
                                                            className="iconDelete position-absolute top-0 right-0"
                                                            onClick={() => handleDeleteFolder(v?.id, history)}
                                                        >
                                                            <TiDelete />
                                                        </div>
                                                    )}
                                            </div>
                                        </Col> : v.type == 20 && fromdDocuments && <Col
                                            xs={6}
                                            sm={4}
                                            md={2}
                                            lg={4}
                                            xl={2}
                                            style={{ display: "flex", justifyContent: "center" }}
                                            key={i}
                                        >
                                            <div className="position-relative w-100 folderWrapperBox">
                                                <div
                                                    variant="link"
                                                    style={{ textDecoration: "none", color: "black" }}
                                                    onClick={() => onClickMainFolder(v)}
                                                    className="d-flex flex-column justify-content-center align-items-center p-0 folder-box"
                                                >
                                                    {v?.violation ? (
                                                        <ViolatedFolderIcon style={{ fontSize: "50px" }} />
                                                    ) : (
                                                        <FcOpenedFolder style={{ fontSize: "50px" }} />
                                                    )}
                                                    <p>{v?.name}</p>
                                                </div>
                                                {(matchRole === constants.ROLES.ROLE_ADMIN ||
                                                    matchRole === constants.ROLES.ROLE_AGENT) && (
                                                        <div
                                                            className="iconDelete position-absolute top-0 right-0"
                                                            onClick={() => handleDeleteFolder(v?.id, history)}
                                                        >
                                                            <TiDelete />
                                                        </div>
                                                    )}
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            ) : (
                                <Row style={{ padding: "10px" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <p>No Data..</p>
                                    </div>
                                </Row>
                            )}
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={5}>
                        <InterLockTableData data={tableListingData} />
                    </Col>
                </Row>
            </div>
        )}

        {/* Modals */}
        {!fromdDocuments &&
            <>
                <ThemeModal
                    title="Add A Folder"
                    size="md"
                    content={<AddFolderForm />}
                    show={modalShowFolder}
                    onHide={() => setModalShowFolder(false)}
                />
                <ThemeModal
                    title="Add A Sub Folder"
                    size="md"
                    content={<AddSubFolderForm />}
                    show={modalShowSubFolder}
                    onHide={() => setModalShowSubFolder(false)}
                />
            </>
        }
        <ThemeModal
            title="Add A File"
            size="md"
            content={<AddFileForm />}
            show={modalShowFileUploader}
            onHide={() => setModalShowFileUploader(false)}
        />
    </>

};

export default InterLockFolders;
