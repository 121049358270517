import { api, axiosService } from "./api.service";
import { constants } from "../utils/constants";

export const SetBaseUrl = () => {
  const storageUser = localStorage.getItem(constants.LOCAL_STORAGE_USER);
  let base_url = constants.BASE_URL;
  if (storageUser !== null) {
    let user = JSON.parse(storageUser)?.user;
    if (user && user.roles) {
      let role_id = user.roles[0].id;
      if (role_id == constants.ROLES.ROLE_ADMIN) {
        base_url = constants.ADMIN_URL;
      } else if (role_id == constants.ROLES.ROLE_VENDOR) {
        base_url = constants.VENDOR_URL;
      } else if (role_id == constants.ROLES.ROLE_CSA) {
        base_url = constants.CSA_URL;
      } else {
        base_url = constants.BASE_URL;
      }
    }
  }
  return base_url;
};

export const POST = async (url, data = null, config = null) => {
  var res = await api.post(SetBaseUrl() + url, data, config);
  return res?.data;
};

export const Post_Without_Token = (url, data = null) => {
  return axiosService.post(SetBaseUrl() + url, data);
};

export const GET = async (url, params = null) => {
  var res = await api.get(SetBaseUrl() + url, {
    params,
  });
  return res?.data;
};

export const PUT = async (url, id, data = null, config = null) => {
  var res = await api.put(SetBaseUrl() + url + "/" + id, data, config);
  return res?.data;
};

export const PATCH = async (url, id, data = null, config = null) => {
  var res = await api.patch(SetBaseUrl() + url + "/" + id, data, config);
  return res?.data;
};

export const DELETE = async (url, id) => {
  var res = await api.delete(SetBaseUrl() + url + "/" + id);
  return res?.data;
};
