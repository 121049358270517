import React, { useRef, useState, useEffect } from "react";
import Test from "../../assets/images/unk.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TimePicker from "react-times";
import { IoArrowBack } from "react-icons/io5";
import "react-times/css/material/default.css";
import "react-times/css/classic/default.css";
import {
  Row,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
  Nav,
  Image,
  Modal,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  GetUserById,
  GetCommentById,
  GetReplyById,
  UpdateUser,
  TakeTestNow,
  DeleteUser,
  DeactivateUser,
  GetTests,
  GetTestPdf,
  UpdateTimeSlots,
  RestoreDeletedUser,
} from "../../services/users.service";
import { GetAuthUser } from "../../services/auth/auth.service";
import { toast } from "react-toastify";
import { useHistory, Link, NavLink, useLocation } from "react-router-dom";
import Calendar from "react-multi-date-picker";
import moment from "moment";
import { updateUser } from "../../services/firebase.service";
import {
  EmptyLocalStorage,
  GetUserRole,
} from "../../services/auth/auth.service";
import { constants, customStyles } from "../../utils/constants";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import swal from "sweetalert";
import {
  AddIconOutline,
  FilterIcon,
  MenuDots,
  SearchIcon,
  Location,
} from "../../components/SvgIcons";
import MaterialTable from "material-table";
import DataTable from "react-data-table-component";
import BarLoader from "react-spinners/BarLoader";
import { ImLocation2 } from "react-icons/im";
import { FaDownload } from "react-icons/fa";
import axios from "axios";
import saveAs from "file-saver";
import fileDownload from "js-file-download";
import ThemeModal from "../../components/ThemeModal";
import EditUser from "../../views/users/EditUser";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../components/SvgIcons";
import "react-datepicker/dist/react-datepicker.css";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import Spinner from "react-bootstrap/Spinner";
import { AiFillMessage } from "react-icons/ai";
import Comments from "../../components/Comments";
import TemperedUsersModal from "../../components/Modals/TemperedUsersModal";
import GenerateReportModalForm from "../../components/Modals/GenerateReportModal";

function DeletedUserDetails(props) {
  const location = useLocation();
  let history = useHistory();
  let id = props.match.params.id;
  const [time, setTime] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [comments, setComments] = useState(false);
  const [rowComments, setRowComments] = useState([]);
  const [rowReplies, setRowReplies] = useState([]);
  const [confPass, ToggleConfPass] = useState(true);
  const [commentLoader, setCommentLoader] = useState(false);
  const [confirmPass, setConfirmPass] = useState(true);
  const [newtime, setNewTime] = useState("");
  const [reportId, setReportId] = useState("");
  const [reportSN, setReportSN] = useState(0);
  const [tabKey, setTabKey] = useState("home");
  const [emailMenuItem, setEmailMenuItem] = useState('');
  // ===== State for temperd users ====
  const [temperedUsersModal, setTemperedUsersModal] = useState({
    rowData: "",
    status: false,
  })

  const [reportType, setReportType] = useState('custom');
  const current_user = GetAuthUser();
  const refReportType = useRef();
  let now = moment().format("YYYY-MM-DD");
  const [defaultTab, setDefaultTab] = useState([
    { start_time: now + "T22:00:00", end_time: now + "T23:45:00" },
    { start_time: now + "T05:00:00", end_time: now + "T08:00:00" },
    { start_time: now + "T17:00:00", end_time: now + "T20:00:00" },
  ]);
  const [personalizedTab, setPersonalizedTab] = useState([
    { start_time: "", end_time: "T00:15:00" },
    { start_time: "", end_time: "" },
    { start_time: "", end_time: "" },
  ]);
  const [fourTab, setfourTab] = useState([
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
  ]);
  const [sixTab, setSixTab] = useState([
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
  ]);
  const [nineTab, setnineTab] = useState([
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
    { start_time: "", end_time: now + "T00:15:00" },
  ]);

  const onTimeStartChange = (options, tab, index) => {
    if (tab == 1) {
      setTabKey("personalized");
      const temp = [...personalizedTab];
      let start_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_start_date = moment(
        now + start_time,
        "YYYY-MM-DDTHH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      // const utc_start_time = moment.utc(full_start_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp[index].start_time = full_start_date;
      setPersonalizedTab(temp);
    } else if (tab == 2) {
      setTabKey("fourx");
      const temp2 = [...fourTab];
      let start_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_start_date = moment(
        now + start_time,
        "YYYY-MM-DDTHH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      // const utc_start_time = moment.utc(full_start_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp2[index].start_time = full_start_date;
      setfourTab(temp2);
    } else if (tab == 3) {
      setTabKey("sixx");
      const temp3 = [...sixTab];
      let start_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_start_date = moment(
        now + start_time,
        "YYYY-MM-DDTHH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      // const utc_start_time = moment.utc(full_start_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp3[index].start_time = full_start_date;
      setSixTab(temp3);
    } else if (tab == 4) {
      setTabKey("ninex");
      const temp4 = [...nineTab];
      let start_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_start_date = moment(
        now + start_time,
        "YYYY-MM-DDTHH:mm:ss"
      ).format("YYYY-MM-DDTHH:mm:ss");
      // const utc_start_time = moment.utc(full_start_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp4[index].start_time = full_start_date;
      setnineTab(temp4);
    }
  };

  const onTimeEndChange = (options, tab, index) => {
    const current = new Date();
    if (tab == 1) {
      setTabKey("personalized");
      const temp1 = [...personalizedTab];
      let end_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_end_date = moment(now + end_time, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      // const utc_end_time = moment.utc(full_end_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp1[index].end_time = full_end_date;
      setPersonalizedTab(temp1);
    } else if (tab == 2) {
      setTabKey("fourx");
      const temp2 = [...fourTab];
      let end_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_end_date = moment(now + end_time, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      // const utc_end_time = moment.utc(full_end_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp2[index].end_time = full_end_date;
      setfourTab(temp2);
    } else if (tab == 3) {
      setTabKey("sixx");
      const temp3 = [...sixTab];
      let end_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_end_date = moment(now + end_time, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      // const utc_end_time = moment.utc(full_end_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp3[index].end_time = full_end_date;
      setSixTab(temp3);
    } else if (tab == 4) {
      setTabKey("ninex");
      const temp4 = [...nineTab];
      let end_time = moment(
        options.hour + ":" + options.minute + ":00" + " " + options.meridiem,
        ["h:mm A"]
      ).format("HH:mm:ss");
      let now = moment().format("YYYY-MM-DD");
      let full_end_date = moment(now + end_time, "YYYY-MM-DDTHH:mm:ss").format(
        "YYYY-MM-DDTHH:mm:ss"
      );
      // const utc_end_time = moment.utc(full_end_date, "YYYY-MM-DDTHH:mm:ssZ").format()
      temp4[index].end_time = full_end_date;
      setnineTab(temp4);
    }
  };

  const mapStyles = {
    position: "relative",
    width: "100%",
    height: "100%",
  };

  const {
    register,
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  function BorderExample() {
    return <Spinner animation="border" />;
  }

  const { setValue } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(async () => {
    let id = props.match.params.id;
    const queryParameters = new URLSearchParams(window.location.search);
    const report_id = queryParameters.get("report_id");

    if (report_id != null) {
      getCommentByid(report_id);
      setComments(true);
    }

    await getUserByid(id);
    if (data) {
      reset({
        full_name: data.full_name,
        username: data.username,
        email: data.email,
        phone: data.phone,
        dob: data.dob,
        license: data.license,
        officer: data.officer,
        test_schedule: data.test_schedule,
        test_schedule_representation: data.test_schedule_representation,
        random_testing: data.random_testing,
        driving_restriction: data.driving_restriction,
        email_reporting: data.email_reporting,
        deactivate: data.deactivate,
        id: data.id,
        //'company_name': data.company_name,
        //'business_type': data.business_type,
      });
    }
  }, []);

  const getUserByid = async (id) => {
    await GetUserById(id)
      .then((result) => {
        if (result.success) {
          if (result.data) {

            let test_schedules = result.data.test_schedule;
            let str_test_schedule = "";
            const tab = test_schedules.length;
            test_schedules.forEach(function (item, i) {
              let now = moment().format("YYYY-MM-DD");

              const start_date = moment(item.start_time, "HH:mm:ss").format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              const end_date = moment(item.end_time, "HH:mm:ss").format(
                "YYYY-MM-DDTHH:mm:ss"
              );

              let start_time = moment(start_date, "YYYY-MM-DDTHH:mm:ss").format(
                "hh:mm A"
              );
              let end_time = moment(end_date, "YYYY-MM-DDTHH:mm:ss").format(
                "hh:mm A"
              );

              if (tab == 3) {
                setTabKey("personalized");
                const temp1 = [...personalizedTab];

                // const utc_end_time = moment.utc(end_date).format()
                // const utc_start_time = moment.utc(start_date).format()
                temp1[i].end_time = end_date;
                temp1[i].start_time = start_date;
                setPersonalizedTab(temp1);
              } else if (tab == 4) {
                setTabKey("fourx");
                const temp2 = [...fourTab];

                // const utc_end_time = moment.utc(end_date).format()
                // const utc_start_time = moment.utc(start_date).format()
                temp2[i].end_time = end_date;
                temp2[i].start_time = start_date;
                setfourTab(temp2);
              } else if (tab == 9) {
                setTabKey("ninex");
                const temp3 = [...nineTab];

                // const utc_end_time = moment.utc(end_date).format()
                // const utc_start_time = moment.utc(start_date).format()
                temp3[i].end_time = end_date;
                temp3[i].start_time = start_date;

                setnineTab(temp3);
              }

              str_test_schedule =
                str_test_schedule +
                start_time +
                "-" +
                end_time +
                (test_schedules.at(-1) != item ? ", " : "");
            });
            setData({
              agent: result.data.agent,
              full_name: result.data.full_name,
              state: result.data.state,
              email: result.data.email,
              profile_image: result.data.profile_image,
              phone: result.data.phone_number,
              license: result.data.license,
              dob: result.data.dob,
              installation_date: result.data.installation_date ?? "-",
              payment_date: result.data.payment_date ?? "-",
              program: result.data.program ?? "-",
              officer: result.data.officer_name,
              officer1: result.data.officer_1_name,
              officer2: result.data.officer_2_name,
              officer3: result.data.officer_3_name,
              test_schedule: str_test_schedule === "" ? "-" : str_test_schedule,
              test_schedule_representation: result.data.test_schedule_representation === "" ? "-" : result.data.test_schedule_representation,
              random_testing: result.data.random_testing
                ? "Enabled"
                : "Disabled",
              driving_restriction: result.data.driving_restriction
                ? "Enabled"
                : "Disabled",
              email_reporting: result.data.email_reporting,
              deactivate: result.data.deactivate,
              id: result.data.id,
              device_serial: result.data.device_serial ?? "-",
            });
            setEmailMenuItem(result.data.email_reporting_id.toString());
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getCommentByid = async (reportId) => {
    setCommentLoader(true);
    await GetCommentById(reportId)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            setPage(result.current_page);
            setTotalRows(result.total);
            if (result.total == 0) {
              setCommentLoader(false);
            } else {
              result.data.map((dt, index) => {
                getReplyByid(dt.id);
                setReportSN(dt.report_sn);
                rowData.push({
                  id: dt.id,
                  author_name: dt.author_name,
                  author_image: dt.author_image,
                  report_id: dt.report_id,
                  message: dt.message,
                });
              });
            }
            setRowComments(rowData);
          }
        } else {
          setCommentLoader(true);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setCommentLoader(true);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getReplyByid = async (commentId) => {
    await GetReplyById(commentId)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            setPage(result.current_page);
            setTotalRows(result.total);
            result.data.map((dt, index) => {
              rowData.push({
                id: dt.id,
                author_name: dt.author_name,
                message: dt.message,
              });
            });
            setRowReplies(rowData);
            setCommentLoader(false);
          }
        } else {
          setCommentLoader(true);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setCommentLoader(true);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onSubmit = async (data) => {
    let id = props.match.params.id;
    let dt = {
      full_name: data.full_name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      dob: data.dob,
      company_name: " ",
      business_type: " ",
      role_id: constants.ROLES.ROLE_USER,
    };

    if (data.password && data.password_confirmation) {
      dt.password = data.password;
      dt.password_confirmation = data.password_confirmation;
    }
    setDisableButton(true);
    await UpdateUser(id, dt)
      .then(async (data) => {
        if (data.status) {
          await updateUser(data.data).then(() => {
            toast.success(data.message);
            history.push("/manage-users");
          });
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  // Faizan

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(90);
  const [rows, setRows] = useState();
  const [comment, setComment] = useState();
  const [reply, setReply] = useState();
  const [keyword, setKeyword] = useState("");
  const [disableDownload, setDisableDownload] = useState(false);

  useEffect(async () => {
    await getTests();
  }, [keyword, page, perPage]);

  const getTests = async () => {
    setLoading(true);
    let id = props.match.params.id;
    await GetTests(id, keyword, constants.ROLES.ROLE_USER, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            setPage(result.current_page);
            setTotalRows(result.total);
            result.data.map((dt, index) => {
              rowData.push({
                id: dt.id,
                index: result.total - (result.current_page - 1) * 20 - index,
                init_picture: dt.init_picture || constants.ClientImage,
                post_picture: dt.post_picture || constants.ClientImage,
                location: {
                  lat: dt.latitude,
                  lng: dt.longitude,
                },
                is_tempered: dt.is_tempered,
                missed_time: dt.missed_time,
                full_name: dt.full_name || "-",
                report_id: dt.report_id,
                status: dt.status || "-",
                bac: `${dt.bac}` || "-",
                date: dt.updated_at || "-",
                time: dt.updated_at || "-",
                test_type: dt.test_type || "Scheduled",
                video: dt.video,
                comment_id: dt.comment,
                created_at: moment(dt.created_at).fromNow(),
              });
            });
            setRows(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onUserDelete = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      // if (willShip) {
      await DeleteUser(id)
        .then((result) => {
          if (result.success) {
            swal(result.message, {
              icon: "success",
            });
            // getUsers();
            history.push("/manage-user");
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
      // }
    });
  };

  const onUserDeactivate = (id, deactivate) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure you want to ${deactivate == true ? "activate" : "deactivate"
        } this user?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      // if (willShip) {
      var data = { user: id };
      await DeactivateUser(data)
        .then((result) => {
          if (result.success) {
            swal(result.message, {
              icon: "success",
            });
            getUserByid(id);
            // getUsers();
            // history.push("/manage-user");
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
      // }
    });
  };

  const onEdit = (data) => {
    history.push("/manage-user/edit-user/" + data.id, {
      data: data,
    });
  };
  const onViewPage = (data) => {
    history.push("/manage-user/Userdetail/" + data.id);
  };

  const userActions = (data) => {
    return (
      <DropdownButton
        align="end"
        className={"actionDropdown"}
        id="dropdown-item-button"
        title={<MenuDots />}
      >
        <Dropdown.Item as="button" onClick={() => onViewPage(data)}>
          View
        </Dropdown.Item>

        <Dropdown.Item as="button" onClick={() => onEdit(data)}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => onUserDelete(data.id)}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    );
  };
  const getStatusColor = (status) => {
    switch (status) {
      case "Pass":
        return "green";
      case "Fail":
        return "red";
      case "Warning":
        return "#f1c40f";
      case "Possible Tampered":
        return "orange";
      case "Missed":
        return "red";
      default:
        return "red";
    }
  };

  const getStatusElement = (text, color) => (
    <span className="text-wrap" style={{ color, fontWeight: "bold" }}>
      {text}
    </span>
  );

  // const statusCheck = (status, rowData) => {

  //   const statusArr = status.split(" - ");

  //   const firstPart = statusArr[0];
  //   const secondPart = statusArr[1];
  //   const thirdPart = statusArr[2];

  //   if (thirdPart !== undefined) {
  //     const firstElement = getStatusElement(firstPart, "red");
  //     const secondElement = getStatusElement(secondPart, secondPart === "Possible Tampered" ? "orange" : "red");
  //     const thirdElement = getStatusElement(thirdPart, getStatusColor(thirdPart));
  //     return (
  //       <div className="possibleTemperedWrapper">
  //         <div className="text-center cursor-pointer" onClick={() => {
  //           setTemperedUsersModal({
  //             rowData: rowData,
  //             status: true
  //           })
  //         }}>
  //           {firstElement}&nbsp;-&nbsp;
  //           {secondElement}&nbsp;-&nbsp;<br />
  //           {thirdElement}
  //         </div >

  //         <TemperedUsersModal
  //           data={temperedUsersModal?.rowData}
  //           show={temperedUsersModal?.status}
  //           onHide={() =>
  //             setTemperedUsersModal({
  //               rowData: "",
  //               status: false,
  //             })
  //           } />
  //       </div>
  //     );
  //   } else if (secondPart !== undefined) {
  //     const firstElement = getStatusElement(firstPart, secondPart === "Possible Tampered" ? "orange" : "red");
  //     const secondElement = getStatusElement(secondPart, getStatusColor(secondPart));
  //     return (
  //       <div>
  //         {firstElement}&nbsp;-&nbsp;
  //         {secondElement}
  //       </div>
  //     );
  //   } else {
  //     return getStatusElement(status, getStatusColor(status));
  //   }
  // };


  const statusCheck = (status, rowData) => {
    const statuses = status?.split(" - ");

    let pointer = false;

    const generateSpan = (status) => {

      let color;

      switch (status) {
        case 'Missed':
        case 'Late':
          color = 'red';
          break;
        case 'Possible Tampered':
          color = 'orange';
          pointer = true;
          break;
        case 'Pass':
          color = 'green';
          break;
        default:
          color = 'black';
      }
      return <span style={{ color, whiteSpace: "normal" }}>{status}</span>;
    };

    const handleOpenModal = () => {
      if (rowData?.is_tempered) {
        setTemperedUsersModal({
          rowData: rowData,
          status: true
        });
      } else {
        return;
      }

    };



    const spans = statuses?.map((status, index) => (
      <React.Fragment key={`${status}-${index}`}>
        {generateSpan(status)}
        {index < statuses?.length - 1 && <span> - </span>}
      </React.Fragment>
    ));

    return (
      <><div className={`${"fw-bold text-center"} ${pointer && "cursor-pointer"}`} onClick={() => handleOpenModal()}>{spans}</div>


      </>
    );
  };



  const restoreDeleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to restore deleted this user?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      if (willShip) {
        const params = {
          user: id
        }
        await RestoreDeletedUser(params)
          .then((result) => {
            if (result.success) {
              swal(result.message, {
                icon: "success",
              });
              history.push("/deleted-users");
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(error.response.data.message);
            }
          });
      }
    });
  };











  const [LocationModalShow, setLocationModalShow] = useState(false);
  const [LocationModalData, setLocationModalData] = useState([]);

  const CatActions = (data) => {
    return (
      <div
        onClick={() => {
          setLocationModal({
            data: data,
            status: true,
          });
        }}
      >
        <span className="Location-icon custom-center">

          <ImLocation2 className="icon" />
        </span>
      </div>
    );
  };
  // Inprocess
  // const downloadVideo = () => {
  //   const link = "https://vimeo.com/291715535";
  //   axios({
  //     url: link, //your url
  //     method: "GET",
  //     responseType: "blob" // important
  //   }).then(response => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const blob = new Blob([response.data], {
  //       type: "text/plain;charset=utf-8"
  //     });
  //     saveAs(blob, "hello world.txt");
  //   });
  // };

  const saveFile = (data) => {
    saveAs(data);
  };

  const VideoActions = (data) => {
    return (
      <div>
        {/*  onClick={() => saveFile(data)} */}
        <a
          className="custom-center"
          href={data}
          download
          style={{ color: "#000" }}
        >
          <FaDownload className="icon" />
        </a>
      </div>
    );
  };

  const Users = () => {
    const MyDates = [{ id: 1, Date: "6-August-22" }];

    return (
      <div className="users">
        {MyDates.map((user) => (
          <div className="user">{user.Date}</div>
        ))}
      </div>
    );
  };
  const Time = () => {
    const MyTime = [{ id: 1, Time: "02:00PM" }];

    return (
      <div className="users">
        {MyTime.map((user) => (
          <div className="user">{user.Time}</div>
        ))}
      </div>
    );
  };
  const TestType = () => {
    const MyType = [{ id: 1, Type: "Scheduled" }];

    return (
      <div className="users">
        {MyType.map((user) => (
          <div className="user">{user.Type}</div>
        ))}
      </div>
    );
  };

  var time_zone = moment().format("Z");
  const columns = [
    {
      id: "full_name",
      name: "NO",
      selector: (rowData, index) => rowData.report_id,
    },
    {
      // width: "9.09%",
      id: "date",
      name: "DATE",
      selector: (rowData) => rowData.date,
      // moment(rowData.date).utcOffset(time_zone).format("YYYY-MM-DD"),
    },
    {
      // width: "9.09%",
      id: "time",
      name: "TIME",
      selector: (rowData) => {
        const currentDate = moment().format("YYYY-MM-DD");


        const created_at_datetime = new Date(rowData.date);
        return rowData.missed_time ? rowData.missed_time : "-";
        /*return rowData.status == "Missed" && rowData.test_type == "Scheduled"
          ? moment(currentDate + "T" + rowData.missed_time).format("hh:mm A")
          : created_at_datetime.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });*/
      },
    },
    {
      // width: "9.09%",
      id: "bac",
      name: "BAC",
      selector: (rowData) => rowData.bac,
    },
    {
      id: "result",
      name: "RESULT",
      className: "resultCol text-center",
      selector: (rowData) => statusCheck(rowData.status, rowData),
    },
    {
      // width: "9.09%",
      id: "test_type",
      name: "TEST TYPE",
      selector: (rowData) => rowData.test_type,
    },

    {
      // width: "9.09%",
      id: "init_picture",
      name: "PRE",
      selector: (rowData) => rowData.init_picture,
      format: (rowData) => {
        if (rowData.status.length === 0) {
          return "-";
        } else if (rowData.status !== "Missed") {
          return (
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // Prevents looping
                currentTarget.src = constants.ClientImage;
              }}
              onClick={() =>
                setImageModal({
                  url: rowData.init_picture,
                  status: true,
                })
              }
              src={rowData.init_picture}
              className={"img-table img-thumbnail"}
              alt={"Image"}
            />
          );
        } else {
          return "-";
        }
      }

    },

    {
      // width: "9.09%",
      id: "post_picture",
      name: "POST",
      selector: (rowData) => rowData.post_picture,
      format: (rowData) => {
        if (rowData.status.length === 0) {
          return "-";
        } else if (rowData.status !== "Missed") {
          return (
            <img
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // Prevents looping
                currentTarget.src = constants.ClientImage;
              }}
              onClick={() =>
                setImageModal({
                  url: rowData.post_picture,
                  status: true,
                })
              }
              src={rowData.post_picture}
              className={"img-table img-thumbnail"}
              alt={"Image"}
            />
          );
        } else {
          return "-";
        }
      }
    },
    {
      // width: "9.09%",
      id: "action",
      center: true,
      name: "VIDEO",
      // cell: VideoActions,  // Commented out, possibly to be uncommented later
      selector: (rowData) => rowData.video,
      cell: (rowData) => {
        return rowData.status !== "Missed" ? VideoActions(rowData.video) : "-";
      }
    },
    {
      // width: "9.09%",
      id: "action",
      center: true,
      name: "GPS.",
      selector: (rowData) => rowData.location,
      cell: (rowData) => <CatActions data={rowData.location} />,
    },
    {
      // width: "9.09%",
      id: "action",
      name: "NOTES",
      center: true,
      selector: (rowData) => rowData.id,
      cell: (rowData) => (
        <AiFillMessage
          className="custom-center"
          data={rowData.id}
          onClick={() => {
            setReportId(rowData.id);
            setReportSN(rowData.report_id);
            getCommentByid(rowData.id);
            setComments(true);
          }}
        />
      ),
    },
  ];

  const [mediaRequiredError, setMediaRequiredError] = useState(false);
  const [files, setFiles] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [restrictionModalShow, setRestrictionModalShow] = useState(false);
  const [generateReportModalShow, setGenerateReportModalShow] = useState(false);
  const [editProfileModalShow, setEditProfileModalShow] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [menuItem, setMenuItem] = useState(0);
  const [frequency, setFrequency] = useState("4x per Day");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [report, setReport] = useState("");
  const [imageModal, setImageModal] = useState({
    url: "",
    status: false,
  });



  const [locationModal, setLocationModal] = useState({
    data: {},
    status: false,
  });

  const itemChange = (e) => {

    setMenuItem(e.target.value);
  };
  const frequencyChange = (e) => {
    setFrequency(e.target.value);
  };
  const ReportChange = (e) => {
    setReport(e.target.value);
  };
  function CustomDropZone(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      onChange,
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png",
      maxFiles: 10,
      onDrop: (files) => {
        setValue("media", files);

        setMediaRequiredError(false);
      },
    });

    const acceptedFileItems = acceptedFiles.map((file, index) => (
      <li key={file.path} style={{ listStyle: "none", fontSize: "10px" }}>
        {index > 1 ? index + "Files Selected" : index + "File Selected"}
        {/*{file.path} - {file.size} bytes*/}
      </li>
    ));

    const thumbs = files.map((file) => (
      <div className={"thumb"} key={file.name}>
        <div className={"thumbInner"}>
          <img src={file.preview} className={"img"} />
        </div>
      </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    return (
      <div className="dropZoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input type="file" name="media" {...getInputProps()} />
          <UploadIcon />
          <p>Drag images or click to upload</p>
        </div>
        <aside className={"thumbsContainer"}>{thumbs}</aside>
        {/*{
                acceptedFileItems.length > 0 ?
                    <div style={{marginLeft: '10px'}}>
                        <span style={{fontSize: '10px'}}>Selected files</span>
                        <p>{acceptedFileItems.length}</p>
                    </div> : ''
            }*/}

        {/*<aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside>*/}
      </div>
    );
  }

  const onModifySchedule = async () => {
    // var timeZone = new Date().getTimezoneOffset()
    // timeZone = -(timeZone/60)
    const data = {
      user: id,
      slots: [],
      //  timeZone: timeZone
    };
    if (tabKey == "ninex") {
      data.slots = nineTab;
    } else if (tabKey == "personalized") {
      data.slots = personalizedTab;
    } else if (tabKey == "fourx") {
      data.slots = fourTab;
    } else if (tabKey == "sixx") {
      data.slots = sixTab;
    } else {
      data.slots = defaultTab;
    }



    await UpdateTimeSlots(data)
      .then((result) => {
        // fileDownload(result, `${data.full_name}-report.pdf`)

        if (result.success) {
          toast.success(result.message);
          setModalShow(false);
          getUserByid(id);
          // swal(result.message, {
          //   icon: "success",
          // });
          // getUsers();
          // history.push("/manage-user")
        } else {
          toast.error(result.message);
          setModalShow(false);
        }
      })
      .catch((error) => {
        setModalShow(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const ModalForm = () => {
    return (
      <Form onSubmit={handleSubmit(onModifySchedule)}>
        <Row>
          <Tabs
            className="testing-tabs portable-schedule mb-3"
            id="noanim-tab-example"
            onSelect={(k) => setTabKey(k)}
            activeKey={tabKey}
          >
            <Tab eventKey="home" title="Default">
              {defaultTab?.map((data, index) => {


                let start_time = moment(data.start_time).format("hh:mm A");


                let end_time = moment(data.end_time).format("hh:mm A");
                return (
                  <div className="mt-2">
                    <Row>
                      <Col md={6}>
                        <div>Start Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          className="w-100"
                          time={start_time}
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <div>End Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          className="w-100"
                          time={end_time}
                          disabled
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Save"}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="personalized" title="Personalized">
              {personalizedTab.map((data, index) => {
                // let start_time = moment.utc(data.start_time).local().format("hh:mm A");
                // let end_time = moment.utc(data.end_time).local().format("hh:mm A");

                let start_time = moment(data.start_time).format("hh:mm A");

                let end_time = moment(data.end_time).format("hh:mm A");

                return (
                  <div className="mt-2">
                    <Row>
                      <Col md={6}>
                        <div>Start Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeStartChange(options, 1, index)
                          }
                          timeConfig={{
                            from: "00:00 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={start_time}
                        />
                      </Col>

                      <Col md={6}>
                        <div>End Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeEndChange(options, 1, index)
                          }
                          timeConfig={{
                            from: "00:15 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={end_time}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Save"}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="fourx" title="4 X Day">
              {fourTab.map((data, index) => {
                // let start_time = moment.utc(data.start_time).local().format("hh:mm A");
                // let end_time = moment.utc(data.end_time).local().format("hh:mm A");

                let start_time = moment(data.start_time).format("hh:mm A");

                let end_time = moment(data.end_time).format("hh:mm A");
                return (
                  <div className="mt-2">
                    <Row>
                      <Col md={6}>
                        <div>Start Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeStartChange(options, 2, index)
                          }
                          timeConfig={{
                            from: "00:00 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={start_time}
                        />
                      </Col>

                      <Col md={6}>
                        <div>End Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeEndChange(options, 2, index)
                          }
                          timeConfig={{
                            from: "00:15 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={end_time}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Save"}
                  />
                </Col>
              </Row>
            </Tab>
            {/* for six timezone */}
            <Tab eventKey="sixx" title="6 X Day">
              {sixTab?.map((data, index) => {

                let start_time = moment(data.start_time).format("hh:mm A");

                let end_time = moment(data.end_time).format("hh:mm A");
                return (
                  <div className="mt-2">
                    <Row>
                      <Col md={6}>
                        <div>Start Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeStartChange(options, 3, index)
                          }
                          timeConfig={{
                            from: "00:00 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={start_time}
                        />
                      </Col>

                      <Col md={6}>
                        <div>End Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeEndChange(options, 3, index)
                          }
                          timeConfig={{
                            from: "00:15 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={end_time}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Save"}
                  />
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="ninex" title="8 X Day">
              {nineTab.map((data, index) => {
                // let start_time = moment.utc(data.start_time).local().format("hh:mm A");
                // let end_time = moment.utc(data.end_time).local().format("hh:mm A");

                let start_time = moment(data.start_time).format("hh:mm A");


                let end_time = moment(data.end_time).format("hh:mm A");

                return (
                  <div className="mt-2">
                    <Row>
                      <Col md={6}>
                        <div>Start Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeStartChange(options, 4, index)
                          }
                          timeConfig={{
                            from: "00:00 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={start_time}
                        />
                      </Col>

                      <Col md={6}>
                        <div>End Time</div>
                        <TimePicker
                          timeMode="12"
                          theme="classic"
                          onTimeChange={(options) =>
                            onTimeEndChange(options, 4, index)
                          }
                          timeConfig={{
                            from: "00:15 AM",
                            to: "11:45 PM",
                            step: 15,
                            unit: "minutes",
                          }}
                          className="w-100"
                          time={end_time}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input
                    type={"submit"}
                    className={"btn btn-green-theme w-100 mt-3"}
                    value={"Save"}
                  />
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Row>
        {/* <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <input
              type={"submit"}
              className={"btn btn-green-theme w-100 mt-3"}
              value={"Save"}
            />
          </Col>
        </Row> */}
      </Form>
    );
  };
  const ModalFormForModifyRistriction = () => {
    return (
      <>
        <p>Driving Restrictions</p>
        <div className={"modalBodyContent"}>
          <select
            className="form-select w-100"
            value={menuItem}
            onChange={itemChange}
            disabled
          >
            <option name="male">Disabled </option>
            <option name="female">Enabled</option>
          </select>
        </div>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <input
              type={"submit"}
              className={"btn btn-green-theme w-100 mt-3"}
              value={"Save"}
            />
          </Col>
        </Row>
      </>
    );
  };
  const EditProfileModalForm = () => {
    return (
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group>
                  <Form.Label className={"form-label"}>
                    Image<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div className={"box-dotted"}>
                    <CustomDropZone ref={{ ...register("media") }} />
                    {mediaRequiredError ? (
                      <Form.Text className="text-muted validationText hasError">
                        Media is required
                      </Form.Text>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    defaultValue={data.full_name}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="username">
                  <Form.Label>state</Form.Label>
                  <Form.Control
                    placeholder="Enter Username"
                    defaultValue={data.username}
                    id={"formSignUpName"}
                    {...register("username", {
                      required: {
                        value: "required",
                        message: "Username is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.username && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.username.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    defaultValue={data.email}
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                        message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                      },

                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      {...register("password", {
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <Form.Control
                      placeholder="Enter Password Again"
                      // disabled={confirmPass}
                      {...register("password_confirmation", {
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                            value === password.current ||
                            "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      // disabled={confirmPass}
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Edit User"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

  const ModalForImage = () => {
    return (
      <>
        <div className={"modalBodyContentImg"}>
          <img
            style={{ objectFit: "cover", objectPosition: "center" }}
            src={imageModal.url}
            className="img-fluid"
          />
        </div>
      </>
    );
  };

  const ModalForLocation = () => {
    let markersList = [
      {
        lat: locationModal?.data?.data?.lat,
        lng: locationModal?.data?.data?.lng,
      },
    ];
    let [markers, setMarkers] = useState(markersList);
    let onMarkerDragEnd = (coord, index, markers) => {
      const { latLng } = coord;
      const lat = latLng.lat();
      const lng = latLng.lng();
      markers[index] = { lat, lng };
      setMarkers(markers);
    };

    let myMarkers =
      markers &&
      Object.entries(markers).map(([key, val]) => (
        <Marker
          key={key}
          id={key}
          position={{
            lat: val.lat,
            lng: val.lng,
          }}
          draggable={false}
          onDragend={(t, map, coord) => onMarkerDragEnd(coord, key, markers)}
        />
      ));
    return (
      <>
        <div className={"modalBodyContent"}>
          <Map
            google={props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={{
              lat: locationModal?.data?.data?.lat,
              lng: locationModal?.data?.data?.lng,
            }}
          >
            {myMarkers}
          </Map>
          {/* <p>{locationModal.data}</p>
          <p>{locationModal.data}</p> */}
        </div>
      </>
    );
  };

  const ModalFormForEmailNotification = () => {
    const handleItemChange = (e) => {

      setEmailMenuItem(e.target.value);
    };

    const handleSubmit = async () => {
      try {
        const id = props.match.params.id;

        // Assuming 'dt' is the selected value from the dropdown
        const dt = {
          email_reporting: emailMenuItem
        };

        // Call the API to update the user
        const response = await UpdateUser(id, dt);

        if (response.success) {
          // Update the user locally if needed
          setEmailNotification(false)
          toast.success(response.message);
          getUserByid(id);

        } else {
          toast.error(response.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          EmptyLocalStorage();
          // Redirect to the login page
          // history.push("/");
        } else {
          toast.error(error.response?.data?.message || 'An error occurred');
        }
      }
    };

    return (
      <>
        <p>Email Setting to Send Calibration Reports to Officer</p>
        <div className="modalBodyContent">
          <h5>Interval</h5>
          <select
            className="form-select w-100"
            value={emailMenuItem}
            onChange={(e) => handleItemChange(e)}
          >
            <option value="1">Weekly Reporting</option>
            <option value="2">Bi Weekly Reporting</option>
            <option value="3">Monthly Reporting</option>
            <option value="4">Daily Reporting</option>
            <option value="5">Per Test</option>
          </select>
        </div>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6}>
            <button
              type="button"
              className="btn btn-green-theme w-100 mt-3"
              onClick={handleSubmit}
            >
              Save
            </button>
          </Col>
        </Row>
      </>
    );
  };


  const onTakeTestNow = async (data) => {
    let id = props.match.params.id;
    let dt = {
      user: id,
    };
    await TakeTestNow(dt)
      .then(async (data) => {
        if (data.success) {
          toast.success("Notification sent.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const RemoveAssign = async (data) => {
    toast.success("Removed successfully");
  };
  const Deactivated = async (data) => {
    toast.success("Deactivated successfully");
  };

  return (
    <>
      <div className="AddNewUser">
        {/*<BreadcrumbsComponent />*/}
        <div className="custom-user-deatils-div">
          <NavLink
            to={{
              pathname: "/deleted-users",
              state: {
                page: location?.state?.page,
              },
            }}
          >
            <IoArrowBack />
          </NavLink>

          <h3 className="page-heading">
            <span className="custom-color-maroon">Deleted User Details -</span>{" "}
            {data.full_name}
          </h3>
        </div>
        <hr />

        <div className="d-flex align-items-start gap-4 user-detail-wrapper flex-column flex-sm-column flex-lg-row">
          <div className="user-image">
            {data.profile_image ?
              <Image
                src={data.profile_image}
              /> :
              <Image
                src={Test}
                width={196}
              />}
          </div>
          <div className="position-relative d-flex">
            <Row style={{ fontSize: "12px" }} className="">
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Date of Birth:</b> <br />{moment(data.dob).format('MM/DD/YYYY') || "-"}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Agent Name:</b> <br /> {data.agent}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Program:</b> <br /> {data.program}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Email:</b> <br /> {data.email}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>County:</b> <br /> {data.county}
                </span>
              </Col>



              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Phone number:</b> <br /> {data.phone}
                </span>
              </Col>


              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Program:</b> <br /> {data.program}
                </span>
              </Col>

              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>SPN Number:</b> <br /> {data.license}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Installation Date:</b> <br /> {moment(data.installation_date).format('MMMM DD, YYYY')}
                </span>
              </Col>

              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Calibration Frequency:</b> <br /> {data.email_reporting}
                </span>
              </Col>

              <Col xs={12} sm={6} md={6} lg={4} className="mb-2" >
                <span>
                  <b>Payment Date:</b> <br /> {moment(data.payment_date).format('MMMM DD, YYYY')}
                </span>
              </Col>

              <Col xs={12} sm={6} md={6} lg={4} className="mb-2">
                <span>
                  <b>Test Schedule</b> <br />  {data?.test_schedule_representation}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2 pe-5" >
                <span>
                  <b>PR/PO Officer:</b> <br />{" "}
                  {data.officer}
                  {/* {`${data.officer}${data.officer1 ? ", " + data.officer1 : ""
                    }${data.officer2 ? ", " + data.officer2 : ""}${data.officer3 ? ", " + data.officer3 : ""
                    }`} */}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2">
                <span>
                  <b>Time Schedule</b> <br />  {data?.test_schedule}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2">
                <span>
                  <b>Device Serial Number:</b> <br /> {data.device_serial}
                </span>
              </Col>
              <Col xs={12} sm={6} md={6} lg={4} className="mb-2">
                <div className="d-flex justify-content-start">
                  <span>
                    <b>Recover User:</b> <br />
                    <Button className="btn-green-theme" onClick={() => { restoreDeleteUser(data?.id) }}>Restore User</Button>
                  </span>
                </div>
              </Col>


            </Row>
            {/* <DropdownButton
              align="end"
              className={"actionDropdown"}
              id="dropdown-item-button"
              title={<MenuDots />}
            >
              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => onTakeTestNow()}
              >
                Take Test Now
              </Dropdown.Item>

              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => setModalShow(true)}
              >
                Modify Schedule
              </Dropdown.Item>

              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => setRestrictionModalShow(true)}
              >
                Modify Restrictions
              </Dropdown.Item>

              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => setGenerateReportModalShow(true)}
              >
                Generate Report
              </Dropdown.Item>

              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => setEmailNotification(true)}
              >
                Email Notification
              </Dropdown.Item>

              <Dropdown.Item
                className="py-2"
                as="button"
                onClick={() => RemoveAssign()}
              >
                Remove assigned device
              </Dropdown.Item>
              {current_user?.role === constants.ROLES.ROLE_AGENT && (
                <Dropdown.Item
                  className="py-2"
                  as="button"
                  onClick={() => {

                    onUserDeactivate(data.id, data.deactivate);
                  }}
                >

                  {data.deactivate == true ? "Activate" : "Deactivate"}
                </Dropdown.Item>
              )}
            </DropdownButton> */}
          </div>
        </div>

      </div >

      {/* <div className={"usersPage"}>
        <Row>
          <Col>
            <h3 className="page-heading">Test Results</h3>
          </Col>

        </Row>
        <hr />
        <div className={"customOrderFilters"}>
          <Row>

            <Col xs={12} sm={12} md={7} lg={7} xl={7}>
              <Row>
                <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Link to={"/manage-user/add-user"}>
                    <Button className={"btn btn-green-theme h40 w-100"}>
                      <AddIconOutline /> Add New
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="theme-dataGrid products-dataGrid custom-table-border abctimeCol">
          <DataTable
            columns={columns}
            data={rows}
            progressPending={loading}
            customStyles={customStyles}
            selectableRows={false}
            striped
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={perPage}
            onChangePage={(page) => {
              setPage(page);
            }}
            paginationRowsPerPageOptions={[30, 60, 90, 180, 270, 360]}
            onChangeRowsPerPage={(currentRowsPerPage) => {
              setPerPage(currentRowsPerPage);
            }}
            progressComponent={
              <BarLoader
                color={"#961b1a"}
                loading={loading}
                css={"marginTop: 10px"}
                height={"4"}
                width={"100%"}
              />
            }
          />
        </div>
      </div> */}

      <ThemeModal
        title={"Modify Schedule"} //InterLock user modify schedule
        size={"md"}
        content={<ModalForm />}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <ThemeModal
        title={"Modify Restrictions"}
        size={"md"}
        content={<ModalFormForModifyRistriction />}
        show={restrictionModalShow}
        onHide={() => setRestrictionModalShow(false)}
      />
      <ThemeModal
        title={"Generate Report"}
        size={"md"}
        content={<GenerateReportModalForm
          id={id}
        />}
        show={generateReportModalShow}
        onHide={() => setGenerateReportModalShow(false)}
      />
      <ThemeModal
        title={"Edit Profile"}
        size={"md"}
        content={<EditProfileModalForm />}
        show={editProfileModalShow}
        onHide={() => setEditProfileModalShow(false)}
      />
      <ThemeModal
        title={"Calibration Frequency"}
        size={"md"}
        content={<ModalFormForEmailNotification />}
        show={emailNotification}
        onHide={() => setEmailNotification(false)}
      />

      <ThemeModal
        title={"Location"}
        size={"md"}
        className="location-modal"
        content={<ModalForLocation />}
        show={locationModal.status}
        onHide={() =>
          setLocationModal({
            data: {},
            status: false,
          })
        }
      />

      <ThemeModal
        title={""}
        size={"md"}
        content={<ModalForImage />}
        show={imageModal.status}
        onHide={() =>
          setImageModal({
            url: "",
            status: false,
          })
        }
      />

      <TemperedUsersModal
        data={temperedUsersModal?.rowData}
        show={temperedUsersModal?.status}
        onHide={() => setTemperedUsersModal({
          rowData: "",
          status: false
        })}
      />
      <ThemeModal
        title={`Test #${reportSN}`}
        size={"md"}
        className={"comment_s"}
        content={
          <Comments
            showComment={setComments}
            comments={rowComments}
            replies={rowReplies}
            commentLoader={commentLoader}
            reportID={reportId}
          />
        }
        show={comments}
        onHide={() => setComments(false)}
      />
    </>
  );
}

// export default UserDetails;
export default GoogleApiWrapper({
  apiKey: "AIzaSyAicyO1YYM1PGOFQf_PyqTCN-sNH5ciMek",
})(DeletedUserDetails);
