import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
  Image,
  Link
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { Controller, useForm } from "react-hook-form";
import {
  GetUserById,
  UpdateUser,
  GetUsers,
} from "../../services/users.service";
import CustomDropdown from "../../components/CustomDropdown";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Calendar from "react-multi-date-picker";
import moment from "moment";
import { updateUser } from "../../services/firebase.service";
import {
  EmptyLocalStorage,
  GetUserRole,
} from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";
import Select from "react-select";
import { FaFilePdf } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { PuffLoader } from "react-spinners";


export const userTypeData = [
  {
    label: "PORTABLE UNIT",
    value: "10",
  },
  {
    label: "INTERLOCK UNIT",
    value: "20",
  },

]


let TimeZoneData = [
  {
    label: "Houston",
    value: "America/Chicago",
  },
  {
    label: "El Paso",
    value: "America/Denver",
  },
];

function EditUser(props) {
  let history = useHistory();
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [mediaRequiredError, setMediaRequiredError] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(false);
  const [txt, setTxt] = useState(false);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [selectedOptionAgent, setSelectedOption] = useState("");
  const [selectedOptionOfficer, setSelectedOptionOfficer] = useState("");
  const [selectedOptionOfficer1, setSelectedOptionOfficer1] = useState("");
  const [selectedOptionOfficer2, setSelectedOptionOfficer2] = useState("");
  const [selectedOptionOfficer3, setSelectedOptionOfficer3] = useState("");
  const [rowsAgent, setRowsAgent] = useState([]);
  const [rowsOfficer, setRowsOfficer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [rowsRep, setRowsRep] = useState([]);
  const [rowsProgram, setRowsProgram] = useState([
    { label: "Pre Trial", value: "10" },
    { label: "Probation", value: "20" },
    { label: "Sober Court", value: "30" },
    { label: "Verterans Court", value: "40" },
    { label: "Volunteer Sobriety", value: "50" },
    { label: "Family Law", value: "60" },
  ]);
  const [selectedOptionRepresentative, setSelectedOptionRepresentative] =
    useState();
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  const [selectedOptionProgram, setSelectedOptionProgram] = useState();
  // --- PDF Upload State -----
  const [selectedFileCertificate, setSelectedFileCertificate] = useState(false);
  const [fileCertificate, setFileDataCertificate] = useState(false);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [removePdf, setRemovePdf] = useState(false);
  const [removeUploadPdf, setRemoveUploadPdf] = useState(false);
  const [selectedInterlock, setselectedInterlock] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [nextOfficerPageUrl, setNextOfficerPageUrl] = useState(null);
  const [nextAgentPageUrl, setNextAgentPageUrl] = useState(null);
  const [nextRepPageUrl, setNextRepPageUrl] = useState(null);

  const [pageOfficer, setPageOfficer] = useState(1);
  const [pageAgent, setPageAgent] = useState(1);
  const [pageRep, setPageRep] = useState(1);


  const {
    register,
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onInputChange = (e) => {
    const { value } = e.target;

    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setTxt(value);
    }
  };

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(async () => {
    let id = props.match.params.id;
    await getUserByid(id);
  }, []);


  useEffect(async () => {
    await loadUsers("", constants.ROLES.ROLE_AGENT, setRowsAgent, pageAgent, setPageAgent, nextAgentPageUrl, setNextAgentPageUrl);
    await loadUsers("", constants.ROLES.ROLE_OFFICER, setRowsOfficer, pageOfficer, setPageOfficer, nextOfficerPageUrl, setNextOfficerPageUrl);
    await loadUsers("", constants.ROLES.ROLE_REPRESENTATIVE, setRowsRep, pageRep, setPageRep, nextRepPageUrl, setNextRepPageUrl);
  }, [keyword]);


  const loadUsers = async (keyword, userRole, setRowsData, page, setPage, nextPageUrl, setNextPageUrl) => {
    if (isLoading || (nextPageUrl === null && page !== 1)) return;

    setIsLoading(true);
    await GetUsers(keyword, userRole, page, 1000) // FIXME : Send correct perPage value
      .then((result) => {
        if (result.success) {
          const newOfficers = result.data.map((officer) => ({
            value: officer.id,
            label: officer.full_name || "-",
          }));
          setRowsData((prev) => [...prev, ...newOfficers]);
          setPage((prevPage) => prevPage + 1);
          setNextPageUrl(result.next_page_url); // Update nextPageUrl
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          // Handle unauthorized error
        } else {
          toast.error(error.response?.data?.message || "An error occurred");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMoreOfficer = async () => {
    if (!isLoading && nextOfficerPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_OFFICER, setRowsOfficer, pageOfficer, setPageOfficer, nextOfficerPageUrl, setNextOfficerPageUrl);
    }
  };

  const loadMoreAgent = async () => {
    if (!isLoading && nextAgentPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_AGENT, setRowsAgent, pageAgent, setPageAgent, nextAgentPageUrl, setNextAgentPageUrl);
    }
  };

  const loadMoreRep = async () => {
    if (!isLoading && nextRepPageUrl !== null) {
      await loadUsers("", constants.ROLES.ROLE_REPRESENTATIVE, setRowsRep, pageRep, setPageRep, nextRepPageUrl, setNextRepPageUrl);
    }
  };


  useEffect(() => {
    if (data) {
      reset({
        full_name: data.full_name,
        username: data.username,
        email: data.email,
        state: data.state,
        phone_number: data.phone_number,
        dob: data.dob,
        payment_date: data.payment_date,
        installation_date: data.installation_date,
        license: data.license,
        serial: data.device_serial,
        county: data.county,
        VehicleInfo: data.VehicleInfo,
        user_type: data.user_type,
        DrivingLicense: data.DrivingLicense,
        certificateUpload: data.certificateUpload
      });
      let agent = {
        label: data.agent,
        value: data.agent_id,
      };
      let representative = {
        label: data.representative,
        value: data.rep_id,
      };
      let officer = {
        label: data.officer,
        value: data.officer_id,
      };
      let officer1 = {
        label: data.officer_1,
        value: data.officer_1_id,
      };
      let officer2 = {
        label: data.officer_2,
        value: data.officer_2_id,
      };
      let officer3 = {
        label: data.officer_3,
        value: data.officer_3_id,
      };
      let program = {
        label: data.program,
        value: data.program_id,
      };
      setSelectedOption(agent);
      setSelectedOptionOfficer(officer);
      setSelectedOptionOfficer1(officer1);
      setSelectedOptionOfficer2(officer2);
      setSelectedOptionOfficer3(officer3);
      setSelectedOptionRepresentative(representative);
      setSelectedOptionProgram(program);
      setValue("agent", {
        label: data.agent,
        value: data.agent_id,
      });
      setValue("officer", officer);
      setValue("officer1", officer1);
      setValue("officer2", officer2);
      setValue("officer3", officer3);
      setValue("representative", {
        label: data.representative,
        value: data.rep_id,
      });
      setValue("program", {
        label: data.program,
        value: data.program_id,
      });
      setValue("TimeZone", {
        label: data.TimeZone == "America/Chicago" ? "Houston" : "El Paso",
        value: data.TimeZone,
      });
    }
  }, [data]);

  const getAgents = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_AGENT, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsAgent(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getOfficers = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_OFFICER, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsOfficer(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getRepresentative = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_REPRESENTATIVE, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsRep(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };


  const getUserByid = async (id) => {
    await GetUserById(id)
      .then((result) => {
        setLoading(true);
        if (result.success) {

          if (result.data) {
            let test_schedules = result.data.test_schedule;
            let str_test_schedule = "";
            test_schedules.forEach((item) => {
              const current = new Date();
              let start_time = moment(
                `${current.getFullYear()}-${current.getMonth() + 1
                }-${current.getDate()}` +
                "T" +
                item.start_time +
                "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              let end_time = moment(
                `${current.getFullYear()}-${current.getMonth() + 1
                }-${current.getDate()}` +
                "T" +
                item.end_time +
                "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              str_test_schedule =
                str_test_schedule +
                start_time +
                "-" +
                end_time +
                (test_schedules.at(-1) != item ? ", " : "");
            });

            setDefaultImage(
              result.data.profile_image || constants.USERIMAGENOTFOUND
            );
            setData({
              full_name: result.data.full_name,
              state: result.data.state,
              email: result.data.email,
              profile_image: result.data.profile_image,
              phone_number: result.data.phone_number,
              license: result.data.license,
              officer_id: result.data.officer_id,
              officer_1_id: result.data.officer_1_id,
              officer_2_id: result.data.officer_2_id,
              officer_3_id: result.data.officer_3_id,
              agent_id: result.data.agent_id,
              rep_id: result.data.rep_id,
              officer: result.data.officer_name,
              officer_1: result.data.officer_1_name,
              officer_2: result.data.officer_2_name,
              officer_3: result.data.officer_3_name,
              payment_date: result.data.payment_date,
              installation_date: result.data.installation_date,
              agent: result.data.agent,
              representative: result.data.representative,
              device_serial: result.data.device_serial,
              user_type: result.data.user_type,
              user_type_selected: userTypeData.filter((item) =>
                (result.data.user_type || []).includes(Number(item.value))
              ),
              county: result.data.county,
              program: result.data.program,
              TimeZone: result.data.timezone,
              VehicleInfo: result.data.vehicle_info,
              DrivingLicense: result.data.license_no,
              dob: result.data.dob,
              test_schedule: str_test_schedule === "" ? "-" : str_test_schedule,
              random_testing: result.data.random_testing
                ? "Enabled"
                : "Disabled",
              driving_restriction: result.data.driving_restriction
                ? "Enabled"
                : "Disabled",
              email_reporting: result.data.email_reporting,
              user_type: result.data.user_type,
              certificateUpload: result.data.installation_certificate
            });
            setLoading(false);

            setselectedInterlock(result.data?.user_type?.includes(20))
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onSubmit = async (data) => {
    let id = props.match.params.id;

    // setDisableButton(true);
    let url = "";
    let urlCertificate = null;

    //======================= Upload Image =================
    if (data.media.length > 0) {
      if (
        data.media[0].type == "image/jpg" ||
        data.media[0].type == "image/png" ||
        data.media[0].type == "image/jpeg"
      ) {
        const myFile = new File([data.media[0]], `${data.full_name}`, {
          type: data.media[0].type,
        });
        let uploadImage = await UploadImageToS3(myFile);
        if (uploadImage) {
          if (!uploadImage.success) {
            toast.error(uploadImage.message);
            return false;
          }
          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          // filename = file.name;
        }
      } else {
        setDisableButton(false);
        return toast.error("Invalid file type.");
      }
    } else {
      url = defaultImage;
    }

    //======================= Upload Certificate =================
    let dt = {
      full_name: data.full_name,
      state: data.state,
      email: data.email,
      phone_number: data.phone_number,
      license: data.license,
      dob: data.dob,
      serial: data.serial,
      profile_image: data.media.length > 0 ? url : defaultImage,
      agent: data.agent.value,
      officer_primary: data.officer.value,
      officer_1: data.officer1.value,
      officer_2: data.officer2.value,
      officer_3: data.officer3.value,
      payment_date: data.payment_date,
      installation_date: data.installation_date,
      representative: data.representative.value,
      program: data.program.value,
      county: data.county,
      user_type: data.userType.map(option => option.value).sort((a, b) => a > b ? 1 : -1),
      timezone: data.TimeZone.value,
      vehicle_info: data.VehicleInfo,
      license_no: data.DrivingLicense,
      installation_certificate: null
    };

    if (data.certificateUpload && (urlRegex.test(data.certificateUpload) || urlRegex.test(data.installation_certificate))) {
      dt.installation_certificate = removePdf || removeUploadPdf ? null : data.certificateUpload
    } else {
      if (selectedFileCertificate.type == "application/pdf") {
        const myFile = new File(
          [selectedFileCertificate],
          `${selectedFileCertificate.name}`,
          {
            type: selectedFileCertificate.type,
          }
        );

        let pdf_URL = await UploadImageToS3(myFile);
        if (pdf_URL) {
          if (!pdf_URL.success) {
            toast.error(pdf_URL.message);
            return false;
          }
          urlCertificate = pdf_URL?.data;
          dt.installation_certificate = urlCertificate;
        }
      }
    }


    if (data.password) {
      dt.password = data.password;
    }

    await UpdateUser(id, dt)
      .then(async (data) => {
        if (data.success) {
          // await updateUser(data.data).then(() => {
          toast.success(data.message);
          history.push("/manage-user");
          // });
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };


  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  function CustomDropZone(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      onChange,
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png",
      maxFiles: 1,
      onDrop: (files) => {
        setValue("media", files);
        setMediaRequiredError(false);

        setSelectedFile(files[0]);
        setFiles(
          files.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const acceptedFileItems = acceptedFiles.map((file, index) => (
      <li key={file.path} style={{ listStyle: "none", fontSize: "10px" }}>
        {index > 1 ? index + "Files Selected" : index + "File Selected"}
        {/*{file.path} - {file.size} bytes*/}
      </li>
    ));

    const thumbs = files.map((file) => (
      <div className={"thumb"} key={file.name}>
        <div className={"thumbInner"}>
          <img src={file.preview} className={"img"} />
        </div>
      </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));



    return (
      <div className="dropZoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input type="file" name="profile_image" {...getInputProps()} />
          <UploadIcon />
          <p>Drag images or click to upload</p>
        </div>
        <aside className={"thumbsContainer"}>
          {/* <img src={data.profile_image}></img> */}
          {thumbs}
        </aside>
        {/*{
                acceptedFileItems.length > 0 ?
                    <div style={{marginLeft: '10px'}}>
                        <span style={{fontSize: '10px'}}>Selected files</span>
                        <p>{acceptedFileItems.length}</p>
                    </div> : ''
            }*/}

        {/*<aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside>*/}
      </div>
    );
  }

  const handleChangeCertificate = (e) => {
    const file = e.target.files[0];
    if (file && file?.type !== "application/pdf") {
      setFileTypeError(true);
      setSelectedFileCertificate(null);
      setFileDataCertificate(null);
    } else {
      setFileTypeError(false);
      setSelectedFileCertificate(file);
      setFileDataCertificate(URL.createObjectURL(file));
      resetField({ certificateUpload: null })
    }
  };

  const handldeRemovefunction = () => {
    setRemovePdf(true)
    setData((prev) => {
      return ({
        ...prev,
        certificateUpload: null
      }
      )
    })
  }

  const handleUploadRemoveFunc = () => {

    setSelectedFileCertificate(false)
    setData((prev) => {
      return ({
        ...prev,
        certificateUpload: null
      }
      )
    })
  }

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      {loading ? <div className="fullScreenLoader"> <PuffLoader color="#960b0c" /> </div> :
        <>
          <h3 className="page-heading">Edit User test - {data?.full_name}</h3>
          <hr />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Row className={"mb-3 "}>
                    <Col xs={12} sm={12}>
                      <div className="d-flex flex-row align-items-center flex-wrap gap-4">
                        <div className="profile-container">
                          <Image
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={file ? file : defaultImage}
                            width="120px"
                            height="20%"
                          />
                        </div>
                        <Form.Group
                          controlId="formFile"
                          onChange={(e) => handleChange(e)}
                          className="custom-file-upload mb-0"
                        >
                          <Form.Label className={"toggleFileUpload mb-0"}>
                            <UploadIcon />
                            Upload Image
                          </Form.Label>
                          <Form.Control
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, image/gif"
                            {...register("media")}
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Row>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="full_name">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        placeholder="Enter Full Name"
                        id={"formSignUpName"}
                        {...register("full_name", {
                          required: {
                            value: "required",
                            message: "Full Name is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "Invalid full name",
                          },
                          minLength: {
                            value: 3,
                            message: "Minimum length is 3",
                          },
                          maxLength: {
                            value: 25,
                            message: "Maximum length is 25",
                          },
                        })}
                        type="text"
                      />
                      {errors.full_name && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.full_name.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        placeholder="Enter Email Address"
                        autocomplete="off"
                        autofill="off"
                        {...register("email", {
                          required: {
                            value: "required",
                            message: "Email is required",
                          },
                          pattern: {
                            value: /\S+@\S+\.\S+/,
                            message: "Invalid email",
                          },
                          maxLength: {
                            value: 255,
                            message: "Maximum length is 255",
                          },
                        })}
                        type="text"
                      />
                      {errors.email && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.email.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="phone_number">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="tel"
                        placeholder="Enter Phone Number"
                        {...register("phone_number", {
                          required: {
                            value: "required",
                            message: "Phone is required",
                          },
                          pattern: {
                            value: /^\(\d{1,4}\)\s?\d{3}-?\d{3,7}$/,
                            message: "Please enter a valid phone number in the format (xxx) xxx-xxxx or (xxx) xxxxxxx",
                          },

                          minLength: {
                            value: 8,
                            message: "Minimum length is 8",
                          },
                          maxLength: {
                            value: 16,
                            message: "Maximum length is 16",
                          },
                        })}
                      />
                      {errors.phone_number && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.phone_number.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group
                      controlId="certificateUpload"
                      onChange={(e) => handleChangeCertificate(e)}
                      className="custom-file-upload mb-0 d-flex flex-column align-items-start"
                    >
                      <Form.Label className="text-left">Installation Certificate</Form.Label>
                      {data.certificateUpload && urlRegex.test(data.certificateUpload) ?
                        <div className="getPDF">
                          <div className="cross" onClick={handldeRemovefunction}>
                            <RxCrossCircled />
                          </div>
                          <a href={data?.certificateUpload} target="_blank" download className="d-flex flex-column align-items-center pdf-holder ">

                            <FaFilePdf style={{ fontSize: "32px", color: "#ff1300" }} />
                          </a>
                        </div>
                        : data.certificateUpload != null || selectedFileCertificate ?
                          <div className="d-flex flex-column align-items-center pdf-holder">
                            <div className="cross" onClick={handleUploadRemoveFunc}>
                              <RxCrossCircled />
                            </div>
                            <FaFilePdf style={{ fontSize: "32px", color: "#ff1300" }} />
                            <p>{selectedFileCertificate?.name}</p>
                          </div>
                          : ""
                      }

                      <Form.Control
                        type="file"
                        accept=".pdf"
                        disabled={data?.certificateUpload}
                        {...register("certificateUpload", {
                          // required: {
                          //   value: "required",
                          //   message: "Installation Certificate is required",
                          // },

                        })}
                      />
                      <Form.Label className={"toggleFileUpload mb-0"}>
                        <UploadIcon />
                        Upload Installation Certificate
                      </Form.Label>
                      {fileTypeError && (
                        <Form.Text className="text-muted validationText hasError">
                          PDF file is required
                        </Form.Text>
                      )}
                      {errors.certificateUpload && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.certificateUpload.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="state">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        placeholder="Enter State"
                        {...register("state", {
                          required: {
                            value: "required",
                            message: "State is required",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "invalid state name entered",
                          },
                          minLength: {
                            value: 3,
                            message: "Minimum length is 3",
                          },
                          maxLength: {
                            value: 25,
                            message: "Maximum length is 25",
                          },
                        })}
                        type="text"
                      />
                      {errors.state && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.state.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="license">
                      <Form.Label>SPN Number</Form.Label>
                      <Form.Control
                        placeholder="Enter License"
                        id={"formSignUpName"}
                        {...register("license", {
                          required: {
                            value: "required",
                            message: "License is required",
                          },
                          minLength: {
                            value: 3,
                            message: "Minimum length is 3",
                          },
                          maxLength: {
                            value: 25,
                            message: "Maximum length is 25",
                          },
                        })}
                        type="text"
                      />
                      {errors.license && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.license.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="user_type">
                      <Form.Label>User Type</Form.Label>
                      <Controller
                        control={control}
                        name="userType"
                        rules={{
                          required: true,
                        }}
                        defaultValue={data?.user_type_selected}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            name={name}
                            value={
                              value && Array.isArray(value)
                                ? value.map((val) =>
                                  userTypeData.find((option) => option === val)
                                )
                                : []
                            }
                            onChange={(selectedOptions) => {
                              // Extract only the value field from selected options
                              const selectedValues = selectedOptions ? selectedOptions.map(option => option) : [];

                              if (selectedValues.sort((a, b) => parseInt(a.value) - parseInt(b.value)).filter(option => option.value == 20).length > 0) {
                                setselectedInterlock(true)
                              }
                              else {
                                setselectedInterlock(false)
                              }

                              onChange(selectedValues);  // Update form state
                            }}
                            options={userTypeData}
                            isSearchable={true}
                            isMulti={true}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        )}
                      />
                      {errors.user_type && (
                        <Form.Text className="text-muted validationText hasError">
                          User Type is required
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>

                  <CustomDropdown
                    label="Officer 1"
                    controlID="officer"
                    control={control}
                    rowsData={rowsOfficer}
                    loadMore={loadMoreOfficer}
                    isLoading={isLoading}
                  />

                  <CustomDropdown
                    label="Officer 2"
                    controlID="officer1"
                    control={control}
                    rowsData={rowsOfficer}
                    loadMore={loadMoreOfficer}
                    isLoading={isLoading}
                  />

                  <CustomDropdown
                    label="Officer 3"
                    controlID="officer2"
                    control={control}
                    rowsData={rowsOfficer}
                    loadMore={loadMoreOfficer}
                    isLoading={isLoading}
                  />

                  <CustomDropdown
                    label="Officer 4"
                    controlID="officer3"
                    control={control}
                    rowsData={rowsOfficer}
                    loadMore={loadMoreOfficer}
                    isLoading={isLoading}
                  />

                  <CustomDropdown
                    label="Agent"
                    controlID="agent"
                    control={control}
                    rowsData={rowsAgent}
                    loadMore={loadMoreAgent}
                    isLoading={isLoading}
                  />

                  <CustomDropdown
                    label="Representative"
                    controlID="representative"
                    control={control}
                    rowsData={rowsRep}
                    loadMore={loadMoreRep}
                    isLoading={isLoading}
                  />


                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="serial">
                      <Form.Label>Serial Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter serial Number"
                        {...register("serial", {
                          required: {
                            value: "required",
                            message: "Serial number is required",
                          },
                          maxLength: {
                            value: 16,
                            message: "Maximum length is 255",
                          },
                        })}
                      />
                      {errors.serial && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.serial.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>

                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="county">
                      <Form.Label>County</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter County"
                        {...register("county", {
                          required: {
                            value: "required",
                            message: "County is required",
                          },
                          maxLength: {
                            value: 16,
                            message: "Maximum length is 255",
                          },
                          pattern: {
                            value: /^[A-Za-z ]+$/,
                            message: "invalid county name entered",
                          },
                        })}
                      />
                      {errors.county && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.county.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="program">
                      <Form.Label>Program</Form.Label>

                      <Controller
                        control={control}
                        name="program"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            name="program"
                            value={value}
                            onChange={onChange}
                            options={rowsProgram}
                            isSearchable={true}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        )}
                      />

                      {errors.program && (
                        <Form.Text className="text-muted validationText hasError">
                          Program is required
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="dob">
                      <Form.Label>Date Of Birth</Form.Label>
                      <Form.Control
                        placeholder="Enter Date Of Birth"
                        max={format(new Date(), "yyyy-MM-dd")}
                        {...register("dob", {
                          required: {
                            value: "required",
                            message: "dob is required",
                          },
                        })}
                        type="date"
                      />
                      {errors.dob && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.dob.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="installation_date">
                      <Form.Label>Install Date</Form.Label>
                      <Form.Control
                        placeholder="Enter Installation Date"
                        max={format(new Date(), "yyyy-MM-dd")}
                        {...register("installation_date", {
                          required: {
                            value: "required",
                            message: "installation date is required",
                          },
                        })}
                        type="date"
                      />
                      {errors.installation_date && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.installation_date.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="payment_date">
                      <Form.Label>Payment Date</Form.Label>
                      <Form.Control
                        placeholder="Enter Payment Date"
                        max={format(new Date(), "yyyy-MM-dd")}
                        {...register("payment_date", {
                          required: {
                            value: "required",
                            message: "payment date is required",
                          },
                        })}
                        type="date"
                      />
                      {errors.payment_date && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.payment_date.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <div className={"pass-field mb-3"}>
                      <Form.Label>Change Password</Form.Label>
                      <InputGroup controlId="passowrd">
                        <FormControl
                          autocomplete="new-password"
                          autofill="new-password"
                          placeholder="Enter Password"
                          {...register("password", {
                            minLength: {
                              value: 8,
                              message: "Minimum length is 8",
                            },
                            maxLength: {
                              value: 255,
                              message: "Maximum length is 255",
                            },
                          })}
                          onClick={() => setConfirmPass(!confirmPass)}
                          type={pass ? "password" : "text"}
                        />
                        <Button
                          variant="outline-secondary"
                          id="button-addon2"
                          onClick={() => TogglePass(!pass)}
                        >
                          {pass ? <FaEye /> : <FaEyeSlash />}
                        </Button>
                      </InputGroup>
                      {errors.password && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.password.message}
                        </Form.Text>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <Form.Group className="mb-3" controlId="TimeZone">
                      <Form.Label>Timezone</Form.Label>

                      <Controller
                        control={control}
                        name="TimeZone"
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                        }) => (
                          <Select
                            name="TimeZone"
                            value={value}
                            onChange={onChange}
                            options={TimeZoneData}
                            isSearchable={true}
                            className="react-select-container"
                            classNamePrefix="react-select"
                          />
                        )}
                      />

                      {errors.TimeZone && (
                        <Form.Text className="text-muted validationText hasError">
                          Time zone is required
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  {selectedInterlock ? (
                    <>
                      <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                        <Form.Group className="mb-3" controlId="VehicleInfo">
                          <Form.Label>Vehicle Info</Form.Label>
                          <Form.Control
                            placeholder="Enter Vehicle Info"
                            // id={"formSignUpName"}
                            {...register("VehicleInfo", {
                              required: {
                                value: "required",
                                message: "Vehicle Info is required",
                              },
                              minLength: {
                                value: 3,
                                message: "Minimum length is 3",
                              },
                              maxLength: {
                                value: 30,
                                message: "Maximum length is 30",
                              },
                            })}
                            type="text"
                          />
                          {errors.VehicleInfo && (
                            <Form.Text className="text-muted validationText hasError">
                              {errors.VehicleInfo.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                      <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                        <Form.Group className="mb-3" controlId="DrivingLicense">
                          <Form.Label>Driving License</Form.Label>
                          <Form.Control
                            placeholder="Enter Driving License"
                            // id={"formSignUpName"}
                            {...register("DrivingLicense", {
                              required: {
                                value: "required",
                                message: "Driving License is required",
                              },
                              minLength: {
                                value: 3,
                                message: "Minimum length is 3",
                              },
                              maxLength: {
                                value: 30,
                                message: "Maximum length is 30",
                              },
                            })}
                            type="text"
                          />
                          {errors.DrivingLicense && (
                            <Form.Text className="text-muted validationText hasError">
                              {errors.DrivingLicense.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </>
                  ) : (
                    ""
                  )}
                </Row>

                <Row>
                  <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                    <input
                      type={"submit"}
                      className={"btn btn-green-theme w-100 mt-3"}
                      disabled={disableButton}
                      value={"Edit User"}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </>
      }
    </div >
  );
}

export default EditUser;
