import { Form, Col, Spinner } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import Select, { components } from "react-select";
import React, { useEffect, useRef, useState } from "react";

const MenuList = (props) => {
    const { isLoading, loadMore } = props.selectProps;

    const handleScroll = (event) => {
      const target = event.target;
      if (!target) return;

      const { scrollTop, scrollHeight, clientHeight } = target;

      if (scrollHeight - scrollTop <= clientHeight + 10 && !isLoading) {
        console.log("Reached bottom, loading more...");
        loadMore();
      }
    };

    return (
      <components.MenuList
        {...props}
        innerProps={{
          ...props.innerProps,
          onScroll: handleScroll,  // Attach the scroll event handler here
        }}
      >
        {props.children}
        {isLoading && (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Spinner animation="border" size="sm" />
          </div>
        )}
      </components.MenuList>
    );
  };



const CustomDropdown = ({ label, controlID, control, rowsData, loadMore, isLoading }) => {

    const { formState: { errors } } = useForm({ mode: "onBlur" });

    return <Col xs={12} sm={12} md={8} lg={4} xl={4}>
        <Form.Group className="mb-3" controlId={controlID}>
            <Form.Label>{label}</Form.Label>
            <Controller
                control={control}
                name={controlID}
                rules={{
                    required: true,
                }}
                render={({ field: { onChange, value } }) => (
                    <Select
                        name={controlID}
                        value={value}
                        onChange={onChange}
                        options={rowsData}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        components={{ MenuList }}
                        loadMore={loadMore}      // Pass loadMore directly
                        isLoading={isLoading}    // Pass isLoading directly
                        // maxMenuHeight={60}
                    />
                )}
            />

            {errors[controlID] && (
                <Form.Text className="text-muted validationText hasError">
                    {label} is required
                </Form.Text>
            )}
        </Form.Group>
    </Col>


};

export default CustomDropdown;